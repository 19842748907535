import React from "react";
import FileListItem from "./FileListItem";
import {getFileByReferenceAndId} from "../../../utils/FileUtils";
import {connect} from "react-redux";
import {ScrollView} from "react-native-gesture-handler";
import commonStyles from "../../../style/common";

const FileList = ({ files, modifiable, onRename, onRemove }) => {
	return (
		<ScrollView style={commonStyles.fullSize}>
			{files.map(file =>
				<FileListItem key={file.id} file={file} modifiable={modifiable} onRename={onRename} onRemove={onRemove} />
			)}
		</ScrollView>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { fileIds, reference, ...otherProps } = ownProps;

	const files = fileIds.map(fileId => getFileByReferenceAndId(state, reference, fileId)).filter(f => !!f);

	return {
		files, ...otherProps
	}
};

export default connect(mapStateToProps)(FileList);