import {createSlice} from '@reduxjs/toolkit';

export const mainMenuPanelSlice = createSlice({
    name: 'mainMenuPanel',
    initialState: {
        opened: false
    },
    reducers: {
        open:   state => ({...state, opened: true}),
        close:  state => ({...state, opened: false}),
        toggle: state => ({...state, opened: !state.opened})
    }
});

export const {open, close, toggle} = mainMenuPanelSlice.actions;

export default mainMenuPanelSlice.reducer;
