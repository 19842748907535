import React from "react";
import InspectionListItem from "./InspectionListItem";
import {ScrollView, Text} from "react-native";
import commonStyles from "../../../style/common";

export default ({inspections}) => {
    if (inspections.length > 0) {
        return (
            <ScrollView style={commonStyles.fullSize}>
                {inspections.map(inspection =>
                    <InspectionListItem
                        key={inspection.id}
                        inspection={inspection} />)}
            </ScrollView>
        );
    } else {
        return <Text>{_tr('There are no related tasks.')}</Text>
    }
};