import Jed from 'jed';
import {LANGS} from '../scripts/translation-settings';

export const currencyFormat = new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'});
export const dateFormat = new Intl.DateTimeFormat('nl-NL');

function setupI18n() {
    let userLang = navigator.language.slice(0, 2);

    if (!(LANGS.includes(userLang))) {
        userLang = 'en';
    }


    try {
        const data = require('../public/langs/' + userLang + '.json');

        setupJed(data || {});
    } catch (e) {
        setupJed({});
    }
}

function setupJed(data) {
    var jed = new Jed(data);

    global.jed = jed;
    global.__ = jed.gettext.bind(jed);

    global._tr = jed.gettext.bind(jed);
    global._trc = jed.pgettext.bind(jed);

    global._tr_plural = function () {
        var args = [].slice.call(arguments);
        args.splice(0, 3, jed.ngettext(args[0], args[1], args[2]));
        return jed.sprintf.apply(jed, args);
    };

    global._trf = function () {
        var args = [].slice.call(arguments);
        args.splice(0, 1, jed.gettext(args[0]));
        return jed.sprintf.apply(jed, args);
    };
    global._trcf = function () {
        var args = [].slice.call(arguments);
        args.splice(0, 2, jed.pgettext(args[0], args[1]));
        return jed.sprintf.apply(jed, args);
    };
}

export {setupI18n}
