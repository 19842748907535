import {createFetchableSlice} from '../../../utils/createFetchableSlice';
import {fetchWorkAssignment, fetchRelatedInspection} from '../../../Api';
import produce from 'immer';

export const workAssignmentSlice = createFetchableSlice({
    name: 'selectedWorkAssignment',
    fetchable: [
        {name: 'workAssignment', api: fetchWorkAssignment},
        {name: 'relatedInspections', api: fetchRelatedInspection}
    ],
    reducers: { 
        postWorkAssignmentCompletedSuccess: produce((draft, action) => {
            draft.data.workAssignment.data.lastSupplierUpdate = action.payload;
        }),
        postWorkAssignmentCompletedFailure: state => {
            alert(_tr('Could not update status'));
            return state;
        }
    }
});

export default workAssignmentSlice.reducer;