import {createSlice} from '@reduxjs/toolkit';
import {setToPayload} from '../utils/reduxUtils';
import {produce} from "immer";

export const loginPageSlice = createSlice({
    name: 'loginPage',
    initialState: {
        opened: true,
        appType: '',
        appRoles: null
    },
    reducers: {
        open:   state => ({...state, opened: true}),
        close:  state => ({...state, opened: false}),
        toggle: state => ({...state, opened: !state.opened}),

        // Revert to the following reducer when backwards compatibility is not needed:
        // setAppType: setToPayload('appType')
        setAppType: produce((draft, action) => {
            const appType = action.payload;
            draft.appType = appType;

            // Backwards compatibility with old servers, that do not respond to /app-roles
            if (draft.appRoles === null) {
                if (appType === 'supplier-app') {
                    draft.appRoles = [ 'supplier' ];
                } else {
                    draft.appRoles = [ 'owner' ];
                }
            }
        }),

        // Revert to the following reducer when backwards compatibility is not needed:
        // setAppRoles: setToPayload('appRoles')
        setAppRoles: produce((draft, action) => {
            if (action.payload !== null) {
                draft.appRoles = action.payload;
            }
        })
    }
});

export const {open, close, toggle, setAppType, setAppRoles} = loginPageSlice.actions;

export default loginPageSlice.reducer;
