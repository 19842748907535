import React from 'react';
import {View, Text} from 'react-native';
import {BlockTitle} from '../components/BlockTitle';
import {ListItem} from 'react-native-elements';
import {StyleSheet} from 'react-native-web';
import {connect} from 'react-redux';
import {navigatorTo} from '../utils/reactUtils';
import {logout} from '../utils/Util';
import {chevronTheme} from "../style/supplierAppStyles";
import { setAppRoles, setAppType } from '../login/LoginPageSlice';

const switchToSupplierApp = (dispatch) => {
    dispatch(setAppType('supplier-app'))
    dispatch(setAppRoles(['supplier', 'administrator']))
}

const Home = ({navigation, dispatch, appRoles, ...otherStuff}) => {
    return (
        <View>
            <View style={styles.appDescriptionContainer}>
                <BlockTitle>{_tr('Welcome')}</BlockTitle>
                <Text>{_tr('With this app you can view your information or make a request. Please look at existing requests before making a new one.')}</Text>
            </View>

            <View>
                <BlockTitle withMargin>{_tr('Navigation')}</BlockTitle>
                { appRoles.includes('owner') && (
                    <ListItem title={_trc('eigendommen', 'Your properties')} onPress={navigatorTo('details', navigation)} chevron={chevronTheme} bottomDivider topDivider />
                )}
                { appRoles.includes('tenant') && (
                    <ListItem title={_tr('Your rental contract')} onPress={navigatorTo('tenantDetails', navigation)} chevron={chevronTheme} bottomDivider topDivider />
                )}

                <ListItem title={_tr('3D model')} onPress={navigatorTo('bimDetails', navigation)} chevron={chevronTheme} bottomDivider />
                <ListItem title={_tr('Requests')} onPress={navigatorTo('recentReports', navigation)} chevron={chevronTheme} bottomDivider />
                {appRoles.includes('administrator') && (
                    <ListItem title={_tr('Switch to supplier-app')} onPress={() => { switchToSupplierApp(dispatch) }} chevron={chevronTheme} bottomDivider />
                )}
                <ListItem title={_tr('Log out')} onPress={() => logout(dispatch)} chevron={chevronTheme} bottomDivider />
            </View>

        </View>
    );
}

const mapStateToProps = state => state.loginPage;

export default connect(mapStateToProps)(Home);

const styles = StyleSheet.create({
    appDescriptionContainer: {
        marginLeft: 10,
        marginVertical: 20
    }
});
