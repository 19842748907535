import React from 'react';
import {StyleSheet} from 'react-native';

export default props => {
    const getStateStyle = value => StyleSheet.flatten(styles[`inspectionState${value}`]);

    const InspectionStateOption = ({value, title}) => (
        <option style={getStateStyle(value)} value={value}>{title}</option>
    );

    return (
        <select style={{...StyleSheet.flatten(styles.inspectionStateSelect), ...getStateStyle(props.value)}} {...props}>
            <InspectionStateOption value='GREAT' title={_tr('Great')} />
            <InspectionStateOption value='WARN' title={_tr('Warning')} />
            <InspectionStateOption value='BAD' title={_tr('Bad')} />
            <InspectionStateOption value='MISSING' title={_tr('Not specified')} />
        </select>
    );
};

const styles = StyleSheet.create({
    inspectionStateSelect: {
        width: '100%',
        height: '40px'
    },
    inspectionStateGREAT: {
        backgroundColor: '#a5eea3'
    },
    inspectionStateWARN: {
        backgroundColor: '#eee185'
    },
    inspectionStateBAD: {
        backgroundColor: '#ee9294'
    },
    inspectionStateMISSING: {
        backgroundColor: '#afafaf'
    },
});
