import axios from 'axios';
import merge from 'lodash.merge';

const axiosConfig = {
	headers: {'Access-Control-Allow-Origin': '*'},
	withCredentials: true
};

export const getData = async (url, config) => {
	const {data} = await axios.get(url, merge({}, axiosConfig, config));
	return data;
};

export const postData = async (url, data, config) => {
	const {data: reponseData} = await axios.post(url, data, merge({}, axiosConfig, config));
	return reponseData;
};

export const getDataRequest = async (url, config) => {
	return axios.get(url, merge({}, axiosConfig, config));
};

export const postDataRequest = (url, data, config) => {
	return axios.post(url, data, merge({}, axiosConfig, config));
};
