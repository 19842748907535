import React from 'react';
import {FileThumbnail} from "./FileThumbnail";
import parsePath from "parse-filepath";
import {View, StyleSheet, Text} from 'react-native';
import {getFileDownloadUrl} from '../../../utils/FileUtils';
import Link from '../../../components/Link';
import commonStyles from '../../../style/common';
import {ListItem} from 'react-native-elements';

const retrieveFilename = file => {
    if (file.isFetching) {
        return '';
    } else if (file.isUploading) {
        return file.jsFile.name;
    } else if (file.file) {
        return file.file.name;
    }
};

export default ({
    file,
    onRename,
    onRemove,
    modifiable = false,
    thumbnailSize = 64
}) => {
    const filename = retrieveFilename(file);

    const handleRename = () => {
        const path = parsePath(filename);
        const newStemName = prompt(`${_trf('Change name for %s', filename)}: `, path.stem);
        if (newStemName) onRename(file, newStemName + path.extname);
    };

    const handleRemove = () => {
        if (confirm(_trf('Are you sure you want to remove %s', filename))) {
            onRemove(file);
        }
    };

    return (
        <ListItem
            containerStyle={styles.container}
            contentContainerStyle={styles.imageContainer}
            title={
                <FileThumbnail size={thumbnailSize} file={file} />
            }
            rightContentContainerStyle={styles.actionsContainer}
            rightTitle={
                <ListItem
                    title={<Link title={filename} style={[commonStyles.bold, commonStyles.fullWidth]} href={getFileDownloadUrl(file)} target="_blank" />}
                    subtitle={modifiable &&
                        <View style={commonStyles.row}>
                            <Link title={_tr('Rename')} onPress={handleRename} />
                            <Text>{'   '}</Text>
                            <Link title={_tr('Remove')} onPress={handleRemove} />
                        </View>
                    }
                />
            }
        />
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 0,
        paddingLeft: '15px'
    },
    imageContainer: {
        minWidth: '25%',
        maxWidth: '25'
    },
    actionsContainer: {
        minWidth: '75%',
        maxWidth: '75%',
        alignItems: 'flex-start'
    }
});
