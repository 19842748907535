import {createFetchableSlice} from '../../utils/createFetchableSlice';
import {
    fetchVveDetails,
    fetchDebiteurIfUserIsDebtor,
    fetchBoekjaren,
    fetchIsTenant
} from '../../Api';

export const vveDetailsSlice = createFetchableSlice({
    name: 'vveDetails',
    fetchable: [
        {name: 'vve', api: fetchVveDetails},
        {name: 'debiteur', api: fetchDebiteurIfUserIsDebtor},
        {name: 'boekjaren', api: fetchBoekjaren},
        {name: 'isTenant', api: fetchIsTenant}
    ]
});

export default vveDetailsSlice.reducer;