import React, {Component} from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';
import {connect} from 'react-redux';
import {createMaterialTopTabNavigator, MaterialTopTabBar} from '@react-navigation/material-top-tabs';
import {SearchBar} from 'react-native-elements';
import {reportsSlice, setFilter} from './ReportsSlice';
import {detailsSlice} from '../details/DetailsSlice';
import {includesIgnoreCase} from '../../utils/Util';
import ReportTab from './ReportTab';
import {headerBackgroundColor, defaultOptions} from '../../style/defaultStyles';
import commonStyles from '../../style/common';
import {findImage, getFileThumbnailUrl} from '../../utils/FileUtils';

const Tab = createMaterialTopTabNavigator();

const REPORT_ICON_SIZE = 50;

class Reports extends Component {
    constructor(props, context) {
        super(props, context);

        this.loadContent = this.loadContent.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.didInvalidate !== prevProps.didInvalidate) {
            this.loadContent();
        }
    }

    loadContent() {
        this.props.dispatch(detailsSlice.actions.fetchIfNeeded());
        this.props.dispatch(reportsSlice.actions.fetchIfNeeded());
    }

    applyFilter(reports, filter) {
        const {details, loaded} = this.props;

        if (!loaded) return reports;

        const complexes = details.vves;

        const filterProperties = [
            'omschrijving',
            'nr',
            'status',
            'aanmaakDatum',
        ];

        return reports.filter(request => {
            const matchesFilterProps = filterProperties.some(prop => {
                return includesIgnoreCase(filter, request[prop].toString());
            });
            if (matchesFilterProps) return true;

            if (request.vve) {
                if (includesIgnoreCase(filter, complexes[request.vve])) return true;
            }

            if (includesIgnoreCase(filter, request.melder.naam)) return true;
        });
    }

    render() {
        const {dispatch, data, loaded} = this.props;
        const {recent, personal, filter = ''} = data;

        const recentFiltered = this.applyFilter(recent, filter);
        const personalFiltered = this.applyFilter(personal, filter);

        const MyTab = () => (
            <ReportTab
                loaded={loaded}
                reports={personalFiltered}
                title={_tr('My recent requests')}
                reportTitle={report => `${_tr('Report')} #${report.nr}`}
                reportSubtitle={report => (
                    <View>
                        <Text style={styles.subtitle}>{report.vve ? report.vve.naam : null}</Text>
                        <Text style={styles.subtitle} numberOfLines={2}>{report.omschrijving}</Text>
                        <Text style={styles.subtitleFooter}>{report.aanmaakDatum}</Text>
                    </View>
                )}
                reportLeftElement={report => {
                    const icon = findImage(report.attachments);

                    return (
                        <View style={styles.personalReportIcon}>
                            {(!!report.attachments.length && !!icon) &&
                                <Image source={getFileThumbnailUrl(icon, REPORT_ICON_SIZE)} style={styles.personalReportIcon} />
                            }
                        </View>
                    );
                }} />
        );

        const RecentTab = () => (
            <ReportTab
                loaded={loaded}
                reports={recentFiltered}
                title={_tr('Recent requests')}
                reportTitle={report => `${report.aanmaakDatum} — ${_tr('Report')} #${report.nr}`}
                reportSubtitle={report => (
                    <View>
                        <Text style={styles.subtitle}>{report.vve ? report.vve.naam : null}</Text>
                        <Text style={styles.subtitle}>{report.omschrijving}</Text>
                    </View>
                )} />
        );

        return (
            <View>
                <Tab.Navigator initialRouteName="Recent" screenOptions={defaultOptions()} tabBar={props => (
                    <View>
                        <MaterialTopTabBar
                            activeTintColor='white'
                            style={styles.tabBar}
                            labelStyle={styles.tabLabel}
                            indicatorStyle={commonStyles.whiteBg}
                            {...props} />
                        <SearchBar
                            lightTheme
                            value={filter}
                            onChangeText={text => dispatch(setFilter(text))}
                            onClear={() => dispatch(setFilter(''))}
                            placeholder={_tr('Filter requests by number, title, state, date, complex or creator...')}
                            containerStyle={styles.searchbarContainer}
                            inputStyle={styles.searchbarInput}
                            inputContainerStyle={styles.searchbarInputContainer} />
                    </View>
                )} >
                    <Tab.Screen name="Recent" component={RecentTab} options={() => ({tabBarLabel: _tr('Recent')})} />
                    <Tab.Screen name="My" component={MyTab} options={() => ({tabBarLabel: _tr('My')})} />
                </Tab.Navigator>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    subtitle: {
        fontSize: '14px',
        color: '#757575',
        lineHeight: '20px',
        maxHeight: '40px'
    },
    subtitleFooter: {
        fontSize: '12px',
        opacity: 0.5,
        lineHeight: '1.2'
    },
    personalReportIcon: {
        width: `${REPORT_ICON_SIZE}px`,
        height: `${REPORT_ICON_SIZE}px`
    },
    blockTitle: {
        margin: '10px'
    },
    searchbarContainer: {
        height: '50px',
        margin: '0',
        padding: '0',
        marginBottom: '20px',
        backgroundColor: 'white',
    },
    searchbarInput: {
        height: '50px',
        backgroundColor: 'white',
        color: 'black',
        fontSize: '14px',
    },
    searchbarInputContainer: {
        backgroundColor: 'white',
        borderBottomWidth: '1.5px',
        borderBottomColor: 'rgba(0, 0, 0, 0.12)'
    },
    tabBar: {
        backgroundColor: headerBackgroundColor,
        shadowColor: "#000",
        shadowOffset: {width: 0, height: 3},
        shadowOpacity: 0.3,
        shadowRadius: 5
    },
    tabLabel: {
        fontSize: '14px',
        fontWeight: '500'
    }
});

const mapStateToProps = state => ({
    ...state.reports,
    data: {
        ...state.reports.data,
        recent: state.reports.data.recent.data,
        personal: state.reports.data.personal.data
    },
    details: state.details.data
});

export default connect(mapStateToProps)(Reports);