import {getData, postData} from "../../../utils/ajax";
import uniqueId from 'lodash.uniqueid';
import {getFileByReferenceAndId} from "../../../utils/FileUtils";

export const FILE_FETCH_REQUEST = 'FILE_FETCH_REQUEST';
export const FILE_FETCH_SUCCESS = 'FILE_FETCH_SUCCESS';
export const FILE_FETCH_FAILURE = 'FILE_FETCH_FAILURE';

export const FILE_RENAME_REQUEST = 'FILE_RENAME_REQUEST';
export const FILE_RENAME_SUCCESS = 'FILE_RENAME_SUCCESS';
export const FILE_RENAME_FAILURE = 'FILE_RENAME_FAILURE';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const ELEMENT_FILE_UPLOAD_SUCCESS = 'ELEMENT_FILE_UPLOAD_SUCCESS';

const fetchFileRequest = (reference, id) => ({
	type: FILE_FETCH_REQUEST,
	payload: {reference, id}
});

const fetchFileSuccess = (reference, file) => ({
	type: FILE_FETCH_SUCCESS,
	payload: {reference, file}
});

const fetchFileFailure = (reference, id, error) => ({
	type: FILE_FETCH_FAILURE,
	payload: {reference, id},
	error: error
});

const fetchFiles = (reference, ids) => {
	return async dispatch => {
		ids.forEach(id => dispatch(fetchFileRequest(reference, id)));

		getData(`${reference.url}/${ids.join(',')}`)
			.then(files => {
				files.forEach(file => {
					dispatch(fetchFileSuccess(reference, file));
				});
			})
			.catch(err => {
				ids.forEach(id => dispatch(fetchFileFailure(reference, id, err)));
			});
	};
};

const fetchFile = (reference, id) => {
	return fetchFiles(reference, [id]);
};

const shouldFetchFile = (state, reference, id) => {
	const file = getFileByReferenceAndId(state, reference, id);

	if (!file) {
		return true;
	} else {
		return !file.isFetching;
	}
};

export const fetchFilesIfNeeded = (reference, ids) => {
	return (dispatch, getState) => {
		const state = getState();
		const neededIds = ids.filter(id => shouldFetchFile(state, reference, id));
		if (neededIds.length) {
			dispatch(fetchFiles(reference, neededIds));
		}
	};
};

export const fetchFileIfNeeded = (reference, id) => {
	return (dispatch, getState) => {
		if (shouldFetchFile(getState(), reference, id)) {
			dispatch(fetchFile(reference, id));
		}
	}
};

const renameFileRequest = (reference, file, name) => ({
	type: FILE_RENAME_REQUEST,
	payload: {reference, file, name}
});

const renameFileSuccess = (reference, file, name) => ({
	type: FILE_RENAME_SUCCESS,
	payload: {reference, file, name}
});

const renameFileFailure = (reference, file, error) => ({
	type: FILE_RENAME_FAILURE,
	payload: {reference, file},
	error
});

export const renameFile = (reference, file, name) => {
	return dispatch => {
		dispatch(renameFileRequest(reference, file, name));
		postData(`${reference.url}/${file.id}/:name`, name, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(() => dispatch(renameFileSuccess(reference, file, name)))
			.catch(err => dispatch(renameFileFailure(reference, file, err)));
	};
};

const uploadFileRequest = (reference, pendingId, jsFile) => ({
	type: FILE_UPLOAD_REQUEST,
	payload: {reference, pendingId, jsFile}
});

const uploadFileProgress = (reference, pendingId, loaded, total) => ({
	type: FILE_UPLOAD_PROGRESS,
	payload: {reference, pendingId, loaded, total}
});

const uploadFileSuccess = (reference, pendingId, file) => ({
	type: FILE_UPLOAD_SUCCESS,
	payload: {reference, pendingId, file}
});

const uploadFileFailure = (reference, pendingId, error) => ({
	type: FILE_UPLOAD_FAILURE,
	payload: {reference, pendingId},
	error
});

const uploadElementFileSuccess = (reference, pendingId, file) => ({
	type: ELEMENT_FILE_UPLOAD_SUCCESS,
	payload: {reference, pendingId, file}
});

export const createPendingFileId = () => -(parseInt(uniqueId()) + 1);

export const uploadFiles = (reference, jsFiles, pendingId = createPendingFileId()) => {
	return dispatch => {
		const promises = [];
		for (let jsFile of jsFiles) {
			dispatch(uploadFileRequest(reference, pendingId, jsFile));

			const formData = new FormData();
			formData.append('files', jsFile);

			// noinspection JSUnusedGlobalSymbols
			promises.push(
				postData(reference.url, formData, {
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: event => dispatch(uploadFileProgress(reference, pendingId, event.loaded, jsFile.size))
				}).then(files => {
					const file = files[0];
					dispatch(uploadFileProgress(reference, pendingId, jsFile.size, jsFile.size));
					dispatch(uploadFileSuccess(reference, pendingId, file));
					return file;
				}).catch(err => {
					dispatch(uploadFileFailure(reference, pendingId, err));
				})
			);
		}

		return Promise.all(promises);
	};
};

export const uploadInspectionElementFiles = (reference, jsFiles, pendingId = createPendingFileId()) => {
	return dispatch => {
		for (let jsFile of jsFiles) {
			dispatch(uploadFileRequest(reference, pendingId, jsFile));

			const formData = new FormData();
			formData.append('files', jsFile);

			// noinspection JSUnusedGlobalSymbols
			postData(reference.url, formData, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: event => dispatch(uploadFileProgress(reference, pendingId, event.loaded, jsFile.size))
			}).then(files => {
				const file = files[0];
				dispatch(uploadFileProgress(reference, pendingId, jsFile.size, jsFile.size));
				dispatch(uploadFileSuccess(reference, pendingId, file));
				dispatch(uploadElementFileSuccess(reference, pendingId, file));
			}).catch(err => {
				dispatch(uploadFileFailure(reference, pendingId, err));
			});
		}
	}
}
