import {createSlice} from "@reduxjs/toolkit";
import {isObject, isFunction, capitalize} from './Util';
import produce from "immer";

/**
 * Wrapper around `createSlice` that makes the default actions `fetch`, `shouldFetch` and `fetchIfNeeded`.
 * Aims to reduce boilerplate by removing the need for creating these actions manually each time.
 * 
 * @param {object} initialState [createDefaultInitialState()] - Initial state of the slice. Not recommended to be used as it overrides the entire initial state, and createFetchableSlice tends to rely on a particular initial state.
 *                                                              To simply add (a) value(s) to the initial state, use {extraInitialState} instead.
 * @param {object} extraInitialState - Object that will be added to the initial state.
 * @param {object} parameters -  Object that takes the name, initialState, reducers and extraReducers of the slice (similar to params for createSlice),
 *                               and `fetchable`, used to determine how to fetch the data.
 * @param {function|object|object[]} fetchable - Part of the `parameters` object. Can be either a `function`, `object` or an `object[]`.
 * 
 *      In case of a `function`, this function will be used as the function that will fetch the data. The function will be called, and when succesful, the result will be stored in `state.data`
 * 
 *      In case of an `object`, the object should have the fields `name` and `api`. The `api` field should be the function that fetches the data, whose result will be stored in `state.data.${name}`> 
 * 
 *      In case of an `object[]`, it will behave the same was as with an object, except it will do so for every object in the array.
 * @param {object} actions - Object of actions that will be added to the slice in addition to the actions created by default. Expects values to be functions that perform the action.
 * 
 * @returns {object} Returns an object with the same properties as `createSlice` (name, reducer, actions, caseReducers).
 * @see DetailsSlice.js for a simple example using a function, or VveDetailsSlice for an example using an object[].
 */
export const createFetchableSlice = parameters => {
    const {fetchable} = parameters;

    if (Array.isArray(fetchable)) {
        return createFetchableSliceFromArray(parameters);
    } else if (isObject(fetchable)) {
        return createFetchableSliceFromObject(parameters);
    } else if (isFunction(fetchable)) {
        return createFetchableSliceFromFunction(parameters);
    } else {
        throw new TypeError(`Fetchable should be either a function, object or object[], but was ${typeof fetchable}.`);
    }
};

const createFetchableSliceFromFunction = ({name, initialState, extraInitialState, reducers, extraReducers, fetchable, actions}) => {
    const slice = createInitialFetchableSlice({name, initialState, extraInitialState, reducers, extraReducers});

    const {fetchRequest, fetchSuccess, fetchFailure} = slice.actions;

    const fetch = apiParams => {
        return async dispatch => {
            dispatch(fetchRequest());

            try {
                const result = await fetchable(apiParams);
                return dispatch(fetchSuccess(result));
            } catch (err) {
                console.error(`Error while fetching ${name}:`, err);
                return dispatch(fetchFailure(err));
            }
        }
    };

    const shouldFetch = state => {
        const relevantState = state[name];

        if (relevantState.isFetching) {
            return false;
        } else if (relevantState.loaded && !relevantState.didInvalidate) {
            return false;
        } else if (!relevantState.data) {
            return true;
        } else {
            return relevantState.didInvalidate;
        }
    };

    const fetchIfNeeded = apiParams => {
        return async (dispatch, getState) => {
            if (shouldFetch(getState())) {
                return dispatch(fetch(apiParams));
            }
        }
    };

    return {
        ...slice,
        actions: {
            ...slice.actions,
            fetch, shouldFetch, fetchIfNeeded,
            ...actions
        }
    };
};

const createFetchableSliceFromObject = parameters => {
    const {fetchable, reducers} = parameters;
    const {name: fetchableName, api} = fetchable;

    return createFetchableSliceFromFunction({
        ...parameters,
        reducers: {
            ...reducers,
            fetchSuccess: (state, action) => {
                const newState = (reducers?.fetchSuccess?.(state, action)) || defaultFetchSuccess(state, action);
                newState.data = {};
                newState.data[fetchableName] = action.payload;
                return newState;
            }
        },
        fetchable: api
    });
};

const createFetchableSliceFromArray = ({name, initialState, extraInitialState, reducers, extraReducers, fetchable, actions}) => {
    const actualInitialState = {
        ...extraInitialState,
        ...(initialState || {
            data: fetchable.reduce((acc, val) => {
                acc[val.name] = createDefaultInitialState();
                return acc;
            }, {}),
            isFetching: false,
            loaded: false,
            error: null,
            didInvalidate: false
        })
    };

    const slice = createInitialFetchableSlice({
        name,
        initialState: actualInitialState,
        reducers: {
            fetchRequest: (state, action) => {
                const newState = defaultFetchRequest(state, action);
                newState.data = actualInitialState.data || null;
                return newState;
            },
            fetchSuccess: (state, action) => {
                const newState = defaultFetchSuccess(state, action);
                newState.data = Object.entries(action.payload).reduce((acc, [name, data]) => {
                    acc[name] = {...defaultFetchSuccess({}, {}), data: data.payload};
                    return acc;
                }, {});
                return newState;
            },
            invalidate: (state, action) => {
                const newState = defaultInvalidate(state, action);
                return produce(newState, draft => {
                    if (draft.data) {
                        for (const [ key, value ] of Object.entries(draft.data)) {
                            draft.data[key] = defaultInvalidate(value);
                        }
                    }
                });
            },
            ...reducers
        },
        extraReducers
    });
    const {fetchRequest, fetchSuccess, fetchFailure} = slice.actions;

    actions = {
        fetch: apiParams => {
            return async dispatch => {
                dispatch(fetchRequest());

                try {
                    const payload = {};
                    for (const slice of fetchableSlices) {
                        const result = await dispatch(slice.actions.fetch(apiParams));
                        payload[slice.name] = result;
                    }
                    dispatch(fetchSuccess(payload));
                    return payload;
                } catch (err) {
                    dispatch(fetchFailure(err));
                    return err;
                }
            }
        },
        shouldFetch: state => {
            const relevantState = state[name];

            return fetchableSlices.some(slice => slice.actions.shouldFetch(relevantState.data));
        },
        fetchIfNeeded: apiParams => {
            return async (dispatch, getState) => {
                if (actions.shouldFetch(getState())) {
                    return dispatch(actions.fetch(apiParams));
                }
            }
        },
        ...actions
    };

    const fetchableSlices = fetchable.map(({name, api}) =>
        createFetchableSliceFromObject({name, initialState, reducers, extraReducers, fetchable: {name, api}}));

    fetchableSlices.forEach(slice => {
        const capitalizedName = capitalize(slice.name);

        actions[`fetch${capitalizedName}`] = slice.actions.fetch;
        actions[`shouldFetch${capitalizedName}`] = slice.actions.shouldFetch;
        actions[`fetch${capitalizedName}IfNeeded`] = slice.actions.fetchIfNeeded;
    });

    return {
        ...slice,
        name,
        actions: {...slice.actions, ...actions}
    };
};

export const createDefaultInitialState = (data = null) => ({
    data,
    isFetching: false,
    loaded: false,
    error: null,
    didInvalidate: false
});

const createInitialFetchableSlice = ({name, initialState = createDefaultInitialState(), extraInitialState, reducers = {}, extraReducers}) => {
    return createSlice({
        name,
        initialState: {...extraInitialState, ...initialState},
        reducers: {
            fetchRequest: reducers.fetchRequest || defaultFetchRequest,
            fetchSuccess: reducers.fetchSuccess || defaultFetchSuccess,
            fetchFailure: reducers.fetchFailure || defaultFetchFailure,
            invalidate: reducers.invalidate || defaultInvalidate,
            ...reducers
        },
        extraReducers
    });
};

const defaultFetchRequest = (state, action) => ({...state, isFetching: true, loaded: false, data: null, error: null});
const defaultFetchSuccess = (state, action) => ({...state, isFetching: false, loaded: true, data: action.payload, error: null});
const defaultFetchFailure = (state, action) => ({...state, isFetching: false, loaded: false, data: null, error: action.error});
const defaultInvalidate = (state, action) => ({...state, didInvalidate: true});
