import React from 'react';
import {getData} from "../../../utils/ajax";
import {apiUrl, TABLET_SCREEN_WIDTH_THRESHOLD} from "../../../constants";
import {View, Text, StyleSheet} from 'react-native';
import {ListItem} from 'react-native-elements';
import {useMediaQuery} from 'react-responsive';
import {useNavigation} from '@react-navigation/native';
import {navigatorTo} from '../../../utils/reactUtils';
import AspectTypeIcon from '../../../components/AspectTypeIcon';
import commonStyles from '../../../style/common';

export default ({schedule}) => {
    const {aspect} = schedule;
    const {aspectType} = aspect;

    const navigation = useNavigation();

    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });
    const handlePress = () => {
        if (confirm(_trf('Register new inspection for %s in %s?', aspectType.name, aspect.complexName))) {
            getData(`${apiUrl}/inspections/new`, {
                params: {
                    aspect: aspect.id,
                    schedule: schedule.id
                }
            }).then(navigatorTo('inspectionDetails', navigation, {
                aspectId: aspect.id,
                scheduleId: schedule.id
            }));
        }
    };

    return (
        <ListItem onPress={handlePress} topDivider containerStyle={styles.container} title={
            <View style={[commonStyles.row, commonStyles.fullWidth, styles.titleContainer]}>
                <AspectTypeIcon aspectType={aspectType} style={{width: isMobileDevice ? '10%' : '5%'}} />
                <View style={{width: isMobileDevice ? '60%' : '25%'}}>
                    <Text style={commonStyles.supplierAppText}>{aspectType.name}</Text>
                </View>
                <View style={{width: isMobileDevice ? '30%' : '15%'}}>
                    <Text style={commonStyles.supplierAppText}>{schedule.scheduledDate}</Text>
                </View>
                <View style={{width: '100%'}}>
                    <Text style={commonStyles.supplierAppText}>{aspect.complexAddress.toString}</Text>
                </View>
            </View>
        } />
    );
};

const styles = StyleSheet.create({
    container: {
        padding: '10px'
    },
    titleContainer: {
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }
});
