import React from 'react';
import {StyleSheet} from 'react-native';
import {ListItem} from 'react-native-elements';
import commonStyles from '../style/common';

export default props => (
    <ListItem
        contentContainerStyle={styles.formRowLeftTitleContainer}
        rightContentContainerStyle={styles.formRowRightTitleContainer}
        rightTitleStyle={[commonStyles.supplierAppText, styles.formRowRightTitle]}
        rightSubtitleStyle={[commonStyles.supplierAppText, styles.formRowRightTitle]}
        bottomDivider
        {...props} />
);

const styles = StyleSheet.create({
    formRowLeftTitleContainer: {
        minWidth: '35%',
        maxWidth: '35%'
    },
    formRowRightTitleContainer: {
        minWidth: '65%',
        maxWidth: '65%'
    },
    formRowRightTitle: {
        marginRight: 'auto'
    }
});
