import {
	FILE_FETCH_FAILURE,
	FILE_FETCH_REQUEST,
	FILE_FETCH_SUCCESS, FILE_RENAME_FAILURE, FILE_RENAME_REQUEST, FILE_RENAME_SUCCESS,
	FILE_UPLOAD_FAILURE,
	FILE_UPLOAD_PROGRESS,
	FILE_UPLOAD_REQUEST,
	FILE_UPLOAD_SUCCESS
} from "./fileActions";
import omit from 'lodash.omit';

const initialState = {
	filesByReference: {}
};

const initialFileStoreState = {
	files: [],
	byId: {}
};

const mapFilesById = files => {
	const map = {};
	files.forEach((f, i) => map[f.id] = i);
	return map;
};

const updatedFilesList = (files, id, updater) => {
	return files.map(file => {
		if (file.id !== id) return file;
		else return updater(file);
	});
};

const fileFetchReducer = (state, action) => {
	switch (action.type) {
		case FILE_FETCH_REQUEST: {
			const files = [...state.files, {
				id: action.payload.id,
				urlPrefix: action.payload.reference.url,
				isFetching: true,
				error: null,
				file: null
			}];
			return {
				...state,
				files,
				byId: mapFilesById(files)
			};
		}
		case FILE_FETCH_FAILURE:
			return {
				...state,
				files: updatedFilesList(state.files, action.payload.id, file => ({
					...file,
					isFetching: false,
					error: action.error
				}))
			};
		case FILE_FETCH_SUCCESS:
			return {
				...state,
				files: updatedFilesList(state.files, action.payload.file.id, file => ({
					...file,
					isFetching: false,
					file: action.payload.file
				}))
			};
		default:
			return state;
	}
};

const fileRenameReducer = (state, action) => {
	switch (action.type) {
		case FILE_RENAME_REQUEST:
			return {
				...state,
				files: updatedFilesList(state.files, action.payload.file.id, file => ({
					...file,
					file: {
						...file.file,
						name: action.payload.name
					}
				}))
			};
		default:
			return state;
	}
};

/**
 * HACKY, removes the 'lastmodifiedDate' property from the file as it causes redux problems.
 * The error seems to be caused by the fact that this property is never equal to itself, so redux thinks we've modified it.
 * More details here: https://github.com/BBB/dropzone-redux-form-example/issues/5
 * @param {File} jsFile - File instance to remove 'lastModifiedDate' property from. 
 */
const fileHack = jsFile => omit(jsFile, 'lastModifiedDate');

const fileUploadReducer = (state, action) => {
	switch (action.type) {
		case FILE_UPLOAD_REQUEST: {
			const files = [...state.files, {
				id: action.payload.pendingId,
				isUploading: true,
				error: null,
				file: null,
				jsFile: fileHack(action.payload.jsFile)
			}];
			return {
				...state,
				files,
				byId: mapFilesById(files)
			};
		}
		case FILE_UPLOAD_SUCCESS: {
			const files = updatedFilesList(state.files, action.payload.pendingId, file => ({
				...file,
				id: action.payload.file.id,
				urlPrefix: action.payload.reference.url,
				isUploading: false,
				file: action.payload.file,
				jsFile: null
			}));

			return {
				...state,
				files,
				byId: mapFilesById(files)
			}
		}
		case FILE_UPLOAD_FAILURE: {
			return {
				...state,
				files: updatedFilesList(state.files, action.payload.pendingId, file => ({
					...file,
					isUploading: false,
					error: action.error
				}))
			}
		}
		case FILE_UPLOAD_PROGRESS: {
			return {
				...state,
				files: updatedFilesList(state.files, action.payload.pendingId, file => ({
					...file,
					progressTotal: action.payload.total,
					progressLoaded: action.payload.loaded
				}))
			}
		}
		default:
			return state;
	}
};

const filesStoreReducer = (state = initialFileStoreState, action) => {
	switch (action.type) {
		case FILE_FETCH_REQUEST:
		case FILE_FETCH_FAILURE:
		case FILE_FETCH_SUCCESS:
			return fileFetchReducer(state, action);
		case FILE_RENAME_REQUEST:
		case FILE_RENAME_SUCCESS:
		case FILE_RENAME_FAILURE:
			return fileRenameReducer(state, action);
		case FILE_UPLOAD_REQUEST:
		case FILE_UPLOAD_SUCCESS:
		case FILE_UPLOAD_FAILURE:
		case FILE_UPLOAD_PROGRESS:
			return fileUploadReducer(state, action);
		default:
			return state;
	}
};

const filesByReferenceReducer = (state, action) => {
	if (action.type.match(/^FILE_/)) {
		const ref = action.payload.reference;
		return {
			...state,
			[ref.key]: filesStoreReducer(state[ref.key], action)
		};
	} else {
		return state;
	}
};

export default (state = initialState, action) => {
	return {
		...state,
		filesByReference: filesByReferenceReducer(state.filesByReference, action)
	}
};
