import React from 'react';
import {ListItem} from 'react-native-elements';
import {useNavigation} from '@react-navigation/native';
import {navigatorTo} from '../../../utils/reactUtils';
import {View, Text, StyleSheet} from 'react-native';
import {useMediaQuery} from 'react-responsive';
import {TABLET_SCREEN_WIDTH_THRESHOLD} from '../../../constants';
import AspectTypeIcon from '../../../components/AspectTypeIcon';
import commonStyles from '../../../style/common';
import {chevronTheme} from "../../../style/supplierAppStyles";

export default ({inspection}) => {
    const {aspect, id, state} = inspection;
    const {aspectType} = aspect;

    const navigation = useNavigation();

    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });

    return (
        <ListItem
            onPress={navigatorTo('inspectionDetails', navigation, {inspection})}
            containerStyle={styles.container}
            title={
                <View style={styles.titleContainer}>
                    <AspectTypeIcon aspectType={aspectType} state={state} style={{width: isMobileDevice ? '10%' : '5%'}} />
                    <Text style={[commonStyles.supplierAppText, {width: isMobileDevice ? '60%' : '25%'}]}>{aspectType.name}</Text>
                    <Text style={[commonStyles.supplierAppText, {width: isMobileDevice ? '30%' : '15%'}]}>{inspection.planDate}</Text>
                    <Text style={[commonStyles.supplierAppText, {width: '100%'}]}>{aspect.complexAddress.toString}</Text>
                </View>
            }
            chevron={chevronTheme}
            topDivider
            bottomDivider
        />
    );
};

const styles = StyleSheet.create({
    container: {
        paddingVertical: '7px',
        paddingHorizontal: '15px'
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    }
});
