import {createSlice} from '@reduxjs/toolkit';
import {setToPayload, toggleReducer} from '../../utils/reduxUtils';

const initialState = {
    complex: '',
    showComplexSelector: false,
    publiclyVisible: true,
    description: '',
    files: []
};

const reportCreateSlice = createSlice({
    name: 'reportCreate',
    initialState,
    reducers: {
        setComplex: setToPayload('complex'),
        toggleShowComplexSelector: toggleReducer('showComplexSelector'),
        setPubliclyVisible: setToPayload('publiclyVisible'),
        togglePubliclyVisible: toggleReducer('publiclyVisible'),
        setDescription: setToPayload('description'),
        setFiles: setToPayload('files'),
        resetForm: _ => initialState
    }
});

export const {setComplex, toggleShowComplexSelector, setPubliclyVisible, togglePubliclyVisible, setDescription, setFiles, resetForm} = reportCreateSlice.actions;

export default reportCreateSlice.reducer;
