import produce from 'immer';

/**
 * Returns a reducer that sets the value for the given key to the action's payload.
 * Can be used to simplify creating this simple reducer:
 *  WITHOUT: setUsername: (state, action) => ({...state, username: action.payload})
 *  WITH:    setUsername: setToPayload('username')
 * @see LoginFormSlice.js for a full example
 * @param {string} key - The key to set to the payload.
 * @returns {Function} Returns a reducer-function that sets the value of the given key to the action's payload. 
 */
export const setToPayload = key => {
    return produce((draft, action) => {
        draft[key] = action.payload
    });
};

export const toggleReducer = key => {
    return produce(draft => {
        draft[key] = !draft[key];
    })
};
