import React, {useEffect} from 'react';
import {ActivityIndicator, Text, View} from 'react-native';
import {ListItem} from 'react-native-elements';
import {connect} from 'react-redux';
import {BlockTitle} from '../../components/BlockTitle';
import {vveDetailsSlice} from './VveDetailsSlice';

const DebtorDetails = ({route, navigation, dispatch, data, loaded, didInvalidate}) => {
    const {vveId} = route.params;
    const {debiteur} = data;
    const incassoMachtiging = debiteur?.incassoMachtiging;

    useEffect(() => {
        dispatch(vveDetailsSlice.actions.fetchIfNeeded({vveId}));

        navigation.setOptions({
            headerTitle: `${_tr('Financial')} ...`
        });
    }, [didInvalidate]);

    if (!loaded) return <ActivityIndicator size='large' />;

    if (!debiteur) return (
        <Text>{_tr('No financial details found')}</Text>
    );

    return (
        <View>
            <View>
                <BlockTitle withMargin>{_tr('Balance')}</BlockTitle>
                <ListItem title={`€ ${debiteur.saldo.toFixed(2)}`} topDivider bottomDivider />
            </View>

            <View>
                <BlockTitle withMargin>{_tr('Direct debit authorisation')}</BlockTitle>
                {!incassoMachtiging && <ListItem title={_tr('None')} topDivider bottomDivider />}
                {incassoMachtiging && <ListItem title={incassoMachtiging.getekendOp} topDivider bottomDivider />}
                {incassoMachtiging && <ListItem title={incassoMachtiging.rekeningNummer} bottomDivider />}
            </View>
        </View>
    );
};

const mapStateToProps = state => ({
    ...state.vveDetails,
    data: {
        debiteur: state.vveDetails.data.debiteur.data,
    }
});

export default connect(mapStateToProps)(DebtorDetails);