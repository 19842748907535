import React from 'react';
import {connect} from 'react-redux';
import {View, Text, StyleSheet, Platform, Linking} from 'react-native';
import {Button, ListItem} from 'react-native-elements';
import {BlockTitle} from '../../components/BlockTitle';
import SimpleFilesView from '../../components/SimpleFilesView';
import {TitledItem} from "../../components/TitledItem";

const ReportDetails = ({route}) => {
    const {report} = route.params;

    return (
        <View>
            <ListItem title={_tr('Date')} rightTitle={report.aanmaakDatum} topDivider bottomDivider />
            <ListItem title={_tr('Requested by')} rightTitle={report.aangemaaktDoor.naam} bottomDivider />
            <ListItem title={_tr('State')} rightTitle={report.status || _tr('Being handled')} bottomDivider />
            {report &&
                <SimpleFilesView files={report.attachments} />}
            {(report.linkedItem && report.linkedItem.viewpointId) && (
                <TitledItem>
                    <Button title={_tr('Open viewpoint in BIM model')}
                            type="outline" raised
                            onPress={() => {
                                const url = `/vve/${report.vve}/project/${report.linkedItem.projectId}/?viewpointId=${report.linkedItem.viewpointId}`;
                                if (Platform.OS === 'web') {
                                    window.open(url, '_blank');
                                } else {
                                    Linking.openURL(url);
                                }
                            }} />
                </TitledItem>
            )}
            <View style={styles.descriptionContainer}>
                {report.omschrijving && <BlockTitle>{_tr('Description')}</BlockTitle>}
                {report.omschrijving && <Text>{report.omschrijving}</Text>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    descriptionContainer: {
        margin: '20px'
    }
});

export default connect()(ReportDetails);