import React from 'react';
import {ActivityIndicator} from 'react-native';
import {BlockTitle} from '../../components/BlockTitle';
import {ListItem} from 'react-native-elements';
import {useNavigation} from '@react-navigation/native';
import {navigatorTo} from '../../utils/reactUtils';
import ScrollableView from '../../components/ScrollableView';
import {chevronTheme} from "../../style/supplierAppStyles";

export default ({loaded, reports, title, reportTitle, reportSubtitle, reportLeftElement}) => {
    const navigation = useNavigation();
    return (
        <ScrollableView>
            {!loaded && <ActivityIndicator size='large' />}
            {loaded && <BlockTitle withMargin>{title}</BlockTitle>}
            {loaded &&
                reports.map((report, i) => (
                    <ListItem
                        key={`report-${report.id}`}
                        onPress={navigatorTo('reportDetails', navigation, {report})}
                        title={reportTitle(report)}
                        leftElement={reportLeftElement && reportLeftElement(report)}
                        subtitle={reportSubtitle && reportSubtitle(report)}
                        badge={{
                            value: report.status,
                            badgeStyle: {
                                backgroundColor: stateColorMap[report.status],
                                borderRadius: '3px'
                            },
                            textStyle: {
                                fontSize: '10px',
                                color: report.status === 'IN_BEHANDELING' ? 'black' : 'white',
                            }
                        }}
                        chevron={chevronTheme} topDivider={i === 0} bottomDivider />
                ))}
        </ScrollableView>
    );
};

const stateColorMap = {
    'NIEUW': 'orange',
    'IN_BEHANDELING': 'yellow',
    'OPGELOST': 'lime',
    'AFGEWEZEN': 'gray'
};
