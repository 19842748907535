export const isImage = file => file && file.contentType && file.contentType.startsWith('image');
export const findImage = files => files && files.find(isImage);

const getFilesByReferenceState = (state, reference) => {
	if (reference.key in state.files.filesByReference) {
		return state.files.filesByReference[reference.key];
	} else {
		return null;
	}
};

export const getFileByReferenceAndId = (state, reference, id) => {
	const filesByReference = getFilesByReferenceState(state, reference);
	return filesByReference && filesByReference.files[filesByReference.byId[id]];
};

export const getFileDownloadUrl = file => {
	const dbFile = file.file;
	if (dbFile)
		return `${file.urlPrefix}/download/${dbFile.id}/${dbFile.hash}/${encodeURIComponent(dbFile.name)}?accessCode=${file.accessCode}`;
	else
		return `/file/download/${file.id}/${file.hash}/${encodeURIComponent(file.name)}?accessCode=${file.accessCode}`;
};

export const getFileThumbnailUrl = (file, size) => {
	const dbFile = file.file;
	if (dbFile)
		return `${file.urlPrefix}/thumbnail/${dbFile.id}/${dbFile.hash}/${encodeURIComponent(dbFile.name)}.thumbnail.png?size=${size}&accessCode=${file.accessCode}`;
	else
		return `/file/thumbnail/${file.id}/${file.hash}/${encodeURIComponent(file.name)}?size=${size}&accessCode=${file.accessCode}`;
};
