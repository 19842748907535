import React from 'react';
import {View, Text, StyleSheet, Dimensions} from 'react-native';
import {useMediaQuery} from 'react-responsive';
import {TABLET_SCREEN_WIDTH_THRESHOLD} from '../../../constants';
import commonStyles from '../../../style/common';
import {ListItem} from 'react-native-elements';

const statusString = status => {
    switch (status) {
        case 'ACTIVE': return _tr('Active')
        case 'INACTIVE': return _tr('Inactive')
        case 'EXPIRED': return _tr('Expired')
        default: throw new Error(`Status '${status}' unknown`)
    }
};

const statusColors = {
    ACTIVE: 'darkgreen',
    INACTIVE: 'grey',
    EXPIRED: 'orange'
};

const getStatus = contract => {
    if (contract.actief) {
        if (contract.eindDatum) {
            const date = new Date(contract.eindDatum);
            const now = new Date();
            if (now < date) {
                return 'ACTIVE';
            } else {
                return 'EXPIRED';
            }
        } else {
            return 'ACTIVE';
        }
    } else {
        return 'INACTIVE';
    }
};

export default ({contract}) => {
    const status = getStatus(contract);

    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });

    return (
        <ListItem
            containerStyle={[commonStyles.fullWidth, commonStyles.noPadding]}
            topDivider
            title={
                <View style={styles.container}>
                    <Text style={[commonStyles.supplierAppText, {width: isMobileDevice ? '75%' : '45%'}]}>
                        {contract.titel}
                    </Text>

                    <Text style={[commonStyles.supplierAppText, commonStyles.bold, {color: statusColors[status], width: isMobileDevice ? '25%' : '15%'}]}>
                        {statusString(status)}
                    </Text>

                    <View style={{width: isMobileDevice ? '50%' : '20%'}}>
                        <Text style={styles.smallText}>{_tr('Start date')}:</Text>
                        <Text style={commonStyles.supplierAppText}>
                            {contract.beginDatum
                                ? contract.beginDatum
                                : _tr('unknown')
                            }
                        </Text>
                    </View>

                    <View style={{width: isMobileDevice ? '50%' : '20%'}}>
                        <Text style={styles.smallText}>{_tr('End date')}:</Text>
                        <Text style={commonStyles.supplierAppText}>
                            {contract.eindDatum
                                ? contract.eindDatum
                                : _tr('No end date')
                            }
                        </Text>
                    </View>
                </View>
            }
        />
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        padding: '10px'
    },
    smallText: {
        fontSize: '14px'
    }
});
