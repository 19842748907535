import React from 'react';
import {StyleSheet} from "react-native";

export const Select = ({ children, ...props }) => {
	return (
		<select style={StyleSheet.flatten(styles.select)} {...props}>
			{children}
		</select>
	);
};

export const SelectOption = props => (
	<option {...props} />
);

const styles = StyleSheet.create({
	select: {
		width: '100%',
		height: '40px'
	}
});
