import 'react-native-gesture-handler';
import '@expo/match-media';
import React, {useState} from 'react';
import LoginPage from './src/login/LoginPage';
import Stack from './src/Stack';
import {store} from './src/setupRedux';
import {Provider} from 'react-redux';
import Modal from 'modal-react-native-web';
import {useEffectOnce} from './src/utils/reactUtils';
import {setupI18n} from './src/i18n';
import {ActivityIndicator} from 'react-native';

export default (props, context) => {
  const [initialized, setInitialized] = useState(false);

  useEffectOnce(() => {
    const initialize = async () => {
      Modal.setAppElement('#root');

      await setupI18n();
  
      setInitialized(true);
    };

    initialize();
  });

  if (initialized) {
    return (
      <Provider store={store}>
        <LoginPage />
        <Stack />
      </Provider>
    );
  }

  return <ActivityIndicator size='large' />;
}