import React, {useEffect} from 'react';
import {View, ActivityIndicator} from 'react-native';
import {ListItem} from 'react-native-elements';
import {BlockTitle} from '../../components/BlockTitle';
import {ScrollView} from 'react-native-gesture-handler';
import {StyleSheet} from 'react-native-web';
import {detailsSlice} from './DetailsSlice';
import {connect} from 'react-redux';
import {chevronTheme} from "../../style/supplierAppStyles";

const Details = ({navigation, data, loaded, dispatch, didInvalidate}) => {
    useEffect(() => {
        dispatch(detailsSlice.actions.fetchIfNeeded());
    }, [didInvalidate]);

    const vves = [];
    if (loaded && data && data.vves) {
        Object.entries(data.vves).forEach(([id, name], i) => vves.push(
            <ListItem
                key={id}
                title={name}
                onPress={() => navigation.navigate('vveDetails', {vveId: id, title: name})}
                topDivider={i === 0}
                bottomDivider
                chevron={chevronTheme} />
        ));
    }

    return (
        <ScrollView>
            {!loaded && <ActivityIndicator size='large' />}
            {loaded &&
                <View>
                    <ListItem title={data.name} style={styles.name} topDivider bottomDivider />
                    {vves &&
                        <>
                            <BlockTitle withMargin>{_tr('Your complexes')}</BlockTitle>
                            <View>
                                {vves}
                            </View>
                        </>
                    }
                </View>
            }
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    name: {
        marginVertical: 20
    }
});

const mapStateToProps = state => state.details;

export default connect(mapStateToProps)(Details);
