import React from 'react';
import {ScrollView} from 'react-native';
import ContractListItem from './ContractListItem';
import commonStyles from '../../../style/common';

export default ({contracts}) => {
    if (contracts && contracts.length > 0) {
        return (
            <ScrollView style={commonStyles.fullSize}>
                {contracts.map(contract => 
                    <ContractListItem key={contract.id} contract={contract} />)}
            </ScrollView>
        );
    }

    return null;
}