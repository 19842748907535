import {createSlice} from '@reduxjs/toolkit';
import {setToPayload} from '../utils/reduxUtils';

const loginFormSlice = createSlice({
    name: 'loginForm',
    initialState: {
        username: '',
        password: ''
    },
    reducers: {
        setUsername: setToPayload('username'),
        setPassword: setToPayload('password')
    }
});

export const {setUsername, setPassword} = loginFormSlice.actions;

export default loginFormSlice.reducer;
