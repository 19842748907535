import React from "react";
import {ListItem} from "react-native-elements";
import {View, Text, StyleSheet} from "react-native";
import {useMediaQuery} from "react-responsive";
import {TABLET_SCREEN_WIDTH_THRESHOLD} from "../../../constants";
import Link from "../../../components/Link";
import {useNavigation} from "@react-navigation/native";
import {navigatorTo} from "../../../utils/reactUtils";
import aspectStatusStyles from "../../../style/aspectStatus";
import commonStyles from "../../../style/common";
import {chevronTheme} from "../../../style/supplierAppStyles";

export default ({elements, statePerElement, inspection}) => {
    const navigation = useNavigation();

    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });

    const itemWidth = isMobileDevice ? '100%' : '33%';

    const showItemsInRow = !isMobileDevice;

    const containerStyle = [commonStyles.fullWidth, (showItemsInRow && commonStyles.row)];

    const Element = ({element}) => {
        const {id, label, elementCode} = element;

        const elemState = statePerElement.find(st => st.element === id);
        const state = elemState ? elemState.state : 'MISSING';

        const code = elementCode && `${elementCode.fullCode} ${elementCode.name}`;

        const onPress = navigatorTo('InspectionElementDetails', navigation, {
            inspection,
            elementId: id
        });

        return (
            <ListItem chevron={chevronTheme} bottomDivider onPress={onPress} title={
                <View style={containerStyle}>
                    <View style={{width: itemWidth}}>
                        <Text style={commonStyles.bold}>{code}</Text>
                    </View>
                    <View style={{width: itemWidth}}>
                        <Link title={label} />
                    </View>
                    <View style={{width: itemWidth}}>
                        <View style={containerStyle}>
                            <View style={[aspectStatusStyles.aspectStatusContainer, aspectStatusStyles[state]]}>
                                <Text style={aspectStatusStyles.aspectStatus}>
                                    {(elemState && elemState.stateDescription)
                                        ? elemState.stateDescription
                                        : {
                                            MISSING: _tr('Not specified'),
                                            GREAT: _tr('Great'),
                                            WARN: _tr('Warning'),
                                            BAD: _tr('Bad')
                                        }[state]
                                    }
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            } />
        );
    };

    const Header = () => (
        <View style={containerStyle}>
            <View style={{width: itemWidth}}>
                <Text>{_tr('Code')}</Text>
            </View>
            <View style={{width: itemWidth}}>
                <Text>{_tr('Label')}</Text>
            </View>
            <View style={{width: itemWidth}}>
                <Text>{_tr('State')}</Text>
            </View>
        </View>
    );

    return (
        <ListItem
            topDivider
            containerStyle={styles.container}
            title={
                <View style={commonStyles.fullWidth}>
                    <Header />

                    {elements.map(element => <Element key={element.id} element={element} />)}
                </View>
            }
        />
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: '0'
    }
});
