import React from 'react';
import {Image, View} from 'react-native';
import aspectStatusStyles from '../style/aspectStatus';

const DEFAULT_ICON_SIZE = 24;

/**
 * Shows the aspectType's icon along with it's state indicator.
 * 
 * @param {object} aspectType - The aspectType object.
 * @param {string} state - State of the inspection.
 * @param {number} width, height - Optional width and height for the image.
 * @param {object} imageProps - Properties passed to the <Image /> element.
 */
export default ({aspectType, state, width = DEFAULT_ICON_SIZE, height = DEFAULT_ICON_SIZE, imageProps, ...props}) => {
    if (!aspectType) return null;

    return (
        <View {...props}>
            <Image source={aspectType.icon.uri} {...imageProps} style={[imageProps ? imageProps.style : {}, {width, height}]} />
            {state &&
                <View style={[aspectStatusStyles.icon, aspectStatusStyles[state]]} />}
        </View>
    );
};
