import React, {useEffect} from 'react';
import {loadInspectionFromRoute, mapInspectionStateToProps} from "./InspectionDetails";
import {updateInspectionElementState, uploadInspectionElementStateFiles} from './InspectionSlice';
import {renameFile} from '../file/fileActions';
import HeaderButton from '../../../components/HeaderButton';
import {View, ActivityIndicator} from 'react-native';
import {connect} from 'react-redux';
import StatePerElementForm from './StatePerElementForm';

const InspectionElementDetails = ({route, dispatch, navigation, inspection, aspectElements, loaded, didInvalidate}) => {
    useEffect(() => {
        navigation.setOptions({
            headerRight: () => <HeaderButton title={_tr('OK')} onPress={navigation.goBack} />
        });

        if (!inspection) {
            loadInspectionFromRoute(route, dispatch);
        }
    }, [route, didInvalidate]);

    const {elementId} = route.params;

    let element = null;
    let elementState = null;
    if (loaded && aspectElements) {
        element = aspectElements.find(ae => ae.id === elementId);
        elementState = inspection.statePerElement.find(s => s.element === elementId) || null;
    }

    const handleOnChangeElementState = (elementId, change) => dispatch(updateInspectionElementState({elementId, change}));
    const handleRenameElementFile = (elementId, file, name) => dispatch(renameFile(makeInspectionReference(inspection), file, name));
    const handleRemoveElementFile = file => dispatch(updateInspectionElementState({
        elementId,
        change: {
            files: elementState.files.filter(f => f !== file.id)
        }
    }));

    const handleElementFileChange = (event, elementId) => {
        const files = event.currentTarget.files;
        dispatch(uploadInspectionElementStateFiles(elementId, files));
    };

    return (
        <View>
            {!loaded && <ActivityIndicator size='large' />}
            {(loaded && element) && (
                <StatePerElementForm
                    inspection={inspection}
                    aspectElement={element}
                    elementState={elementState}
                    onChange={handleOnChangeElementState}
                    onFileChange={handleElementFileChange}
                    onRenameFile={handleRenameElementFile}
                    onRemoveFile={handleRemoveElementFile}
                />
            )}
        </View>
    )
};

export default connect(mapInspectionStateToProps)(InspectionElementDetails);
