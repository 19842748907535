import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {ListItem} from 'react-native-elements';
import commonStyles from '../../../style/common';

export default ({request}) => {
    const {omschrijving, melder, aanmaakdatum, status} = request;

    return (
        <ListItem topDivider bottomDivider title={
            <View style={commonStyles.fullWidth}>
                <Text style={[commonStyles.supplierAppText, styles.description]}>{omschrijving}</Text>
                <Text style={commonStyles.supplierAppText}>{aanmaakdatum}</Text>
                <Text style={commonStyles.supplierAppText}>Melder: {melder.string}</Text>
                <Text style={commonStyles.supplierAppText}>{status}</Text>
            </View>
        } />
    );
};

const styles = StyleSheet.create({
    description: {
        whiteSpace: 'normal'
    }
});
