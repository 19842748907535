import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {workAssignmentSlice} from './WorkAssignmentSlice';
import {ActivityIndicator, Text, View} from 'react-native';
import {BlockTitle} from '../../../components/BlockTitle';
import WorkAssignmentForm from './WorkAssignmentForm';
import {postWorkAssignmentCompleted} from '../../../Api';
import InspectionList from '../inspection/InspectionList';
import ScrollableView from '../../../components/ScrollableView';

const WorkAssignmentDetails = (props) => {
    const {dispatch, route, workAssignment, relatedInspections, contractsLoaded, contracts, loaded, didInvalidate} = props;
    const {workAssignmentId} = route.params;
    useEffect(() => {
        dispatch(workAssignmentSlice.actions.fetch(workAssignmentId));
    }, [didInvalidate]);

    const handleFinishedByDealerChange = val => {
        postWorkAssignmentCompleted(workAssignment.id, val)
            .then(() => dispatch(workAssignmentSlice.actions.postWorkAssignmentCompletedSuccess(val)))
            .catch((err) => console.error(err) || dispatch(workAssignmentSlice.actions.postWorkAssignmentCompletedFailure()))
    };

    return (
        <View>
            <View>
                <BlockTitle withMargin>{_tr('Work assignment')}</BlockTitle>
                {(!loaded || !workAssignment)
                    ? <ActivityIndicator size='large' />
                    : <WorkAssignmentForm workAssignment={workAssignment} onFinishedByDealerChange={handleFinishedByDealerChange} />}
            </View>

            <View>
                <BlockTitle withMargin>{_tr('Related tasks')}</BlockTitle>
                <ScrollableView>
                    {(contractsLoaded && contracts.length === 0)
                        ? <Text>{_tr('No contract found.')}</Text>
                        : (!loaded || !relatedInspections)
                            ? <ActivityIndicator size='large' />
                            : <InspectionList inspections={relatedInspections} />}
                </ScrollableView >
            </View >
        </View>
    );
};

const mapStateToProps = state => ({
    workAssignment: state.selectedWorkAssignment.data.workAssignment.data,
    relatedInspections: state.selectedWorkAssignment.data.relatedInspections.data,
    contracts: state.contracts.data,
    contractsLoaded: state.contracts.loaded,
    loaded: state.selectedWorkAssignment.loaded,
    didInvalidate: state.selectedWorkAssignment.didInvalidate
});

export default connect(mapStateToProps)(WorkAssignmentDetails);
