import React from 'react';
import LoginForm from './LoginForm';
import {fetchStatus, fetchAppType, fetchAppRoles} from '../Api';
import {close, setAppRoles, setAppType} from './LoginPageSlice';
import {connect} from 'react-redux';
import {useEffectOnce} from '../utils/reactUtils';
import {View} from 'react-native';
import commonStyles from '../style/common';

const LoginPage = ({opened, dispatch}) => {
    useEffectOnce(() => onOpenPage());

    const handleClosePage = () => dispatch(close());

    const handleLogin = async () => {
        dispatch(setAppType(await fetchAppType()));
        dispatch(setAppRoles(await fetchAppRoles()));

        handleClosePage();
    };

    const onOpenPage = () => {
        fetchStatus()
            .then(response => {
                if (response.status === '200') {
                    handleLogin();
                }
            });
    }

    return (
        opened ?
            <View style={commonStyles.fullSize}>
                <LoginForm onLogin={handleLogin} />
            </View>
            : null
    )
}

const mapStateToProps = state => state.loginPage;

export default connect(mapStateToProps)(LoginPage);