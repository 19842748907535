import React from 'react';
import {ListItem, Text} from "react-native-elements";
import commonStyles from "../style/common";
import {View} from "react-native";

export const TitledItem = ({ title, text, children, ...props }) => (
	<ListItem {...props} title={(
		<View style={{ width: '100%' }}>
			<Text style={commonStyles.subText}>{title}</Text>
			{ text && (
				<Text style={[ commonStyles.supplierAppText, { marginTop: '.25rem' }]}>{text}</Text>
			)}
			{children}
		</View>
	)}>
	</ListItem>
);