import React from "react";
import groupBy from "lodash.groupby";
import {ListItem} from "react-native-elements";
import {View, Text, StyleSheet} from "react-native";
import Link from "../../../components/Link";
import StatusCounting from "./StatusCounting";
import {useNavigation} from "@react-navigation/native";
import commonStyles from '../../../style/common';
import {chevronTheme} from "../../../style/supplierAppStyles";

export default ({inspection, aspectElements}) => {
    const {statePerElement} = inspection;

    const elementsByLocation = groupBy(aspectElements, 'location');

    const navigation = useNavigation();

    const goToLocation = location => {
        navigation.navigate('InspectionLocationDetails', {location, inspection});
    };

    return (
        <View>
            <ListItem
                containerStyle={commonStyles.noPadding}
                title={
                    <View style={styles.row}>
                        <View style={styles.large}><Text>{_tr('Location')}</Text></View>
                        <View style={styles.small}><Text>{_tr('Amount of elements')}</Text></View>
                        <View style={styles.large}><Text>{_tr('Element state')}</Text></View>
                    </View>
                } />
            {Object.entries(elementsByLocation).map(([location, elements]) => (
                <ListItem
                    key={location}
                    containerStyle={styles.elementContainer}
                    title={
                        <View style={styles.row}>
                            <View style={styles.large}>
                                <Link title={location} onPress={() => goToLocation(location)} />
                            </View>
                            <View style={styles.small}>
                                <Text>{elements.length}</Text>
                            </View>
                            <View style={styles.large}>
                                <ListItem containerStyle={styles.statusCountingContainer} title={
                                    <StatusCounting elements={elements.filter(e => e.location === location)} statePerElement={statePerElement} />
                                } chevron={chevronTheme} />
                            </View>
                        </View>
                    }
                    onPress={() => goToLocation(location)}
                    bottomDivider
                />
            ))}
        </View>
    );
};

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: '16px',
        width: '100%'
    },
    statusCountingContainer: {
        padding: '0',
        paddingRight: '16px'
    },
    elementContainer: {
        padding: '0',
        minHeight: '40px'
    },
    small: {
        width: '20%'
    },
    large: {
        width: '40%'
    }
})
