import React, {useRef} from 'react';
import {View} from "react-native";
import {Button} from 'react-native-elements';
import SimpleFilesView from './SimpleFilesView';

/**
 * Button that opens a file selector when clicked.
 * 
 * @param {function} onSelect - Function that will receive the event when the user has selected files.
 * @param {object[]|object} value - Currently selected file(s).
 * @param {boolean} [false] multiple - Whether user can select multiple files.
 * @param {string} accept - Accepted file types.
 * @param {boolean} [false] hideImages - Whether chosen images are displayed underneath the button.
 */
export default ({title, value, onSelect, multiple = false, accept, hideImages = false, containerStyle, buttonContainerStyle, buttonStyle, imageContainerStyle, imageStyle, ...props}) => {
    const inputRef = useRef(null);
    value = Array.isArray(value) ? value : Array.of(value);

    const openFilePicker = () => inputRef.current.click();

    return (
        <View style={containerStyle}>
            <Button title={title || _tr('Select a file')} onPress={openFilePicker} containerStyle={buttonContainerStyle} buttonStyle={buttonStyle} {...props} />
            <input ref={inputRef} type="file" accept={accept} hidden multiple={multiple} onChange={onSelect} />
            {(!hideImages &&
                <SimpleFilesView
                    files={value}
                    containerStyle={containerStyle}
                    imageContainerStyle={imageContainerStyle} />
            )}
        </View>
    );
};
