import React, {useState} from 'react';
import {View, Text, StyleSheet, TextInput,  Linking, Platform} from 'react-native';
import {Button, ListItem} from 'react-native-elements';
import commonStyles from '../../../style/common';
import {TitledItem} from "../../../components/TitledItem";
import {BlockTitle} from "../../../components/BlockTitle";
import {Select, SelectOption} from "../../../components/Select";
import {blockHeaderStyles} from "../../../style/defaultStyles";

export default ({workAssignment, onFinishedByDealerChange}) => {
    const {vve, omschrijving: description, aanmaakdatumString: date, startdatum: startDate, planningsDatum: planningsDateNumber, reparatiePrioriteit, reparatieSoort, indicatieBedrag, lastSupplierUpdate} = workAssignment;

    const [ draftUpdateStatus, setDraftUpdateStatus ] = useState('COMPLETE');
    const [ draftUpdateDescription, setDraftUpdateDescription ] = useState('');
    const [ isSubmitted, setSubmitted ] = useState(false);

    const priority = reparatiePrioriteit && reparatiePrioriteit.naam;
    const repairType = reparatieSoort && reparatieSoort.naam;

    const vveComposedAddress = vve.composedAddress.toString;

    const planningsDate = planningsDateNumber && new Date(planningsDateNumber).toLocaleDateString();

    const indicationAmount = indicatieBedrag.toString();

    const onFinishedByDealerPress = () => {
        if (!confirm(_trf('Are you sure you want to update the status to "%s"?', SupplierStatuses[draftUpdateStatus].label()))) {
            return;
        }
        onFinishedByDealerChange({
            status: draftUpdateStatus,
            description: draftUpdateDescription
        });
        setSubmitted(true);
    };

    const Item = props => (
        <ListItem style={commonStyles.supplierAppText} containerStyle={styles.itemContainer} topDivider {...props} />
    )

    return (
        <React.Fragment>
            <View>
                <TitledItem topDivider title={_tr('Description')} text={description} />
                <TitledItem topDivider title={_tr('Address')} text={vveComposedAddress} />
                {priority &&
                <TitledItem topDivider title={_tr('Priority')} text={priority} />}
                {repairType &&
                <TitledItem topDivider title={_tr('Repair type')} text={repairType} />}
                {date &&
                <Item title={
                    <View style={commonStyles.row}>
                        <Text style={commonStyles.supplierAppText}>{`${_tr('Creation date')}: `}</Text>
                        <Text style={commonStyles.supplierAppText}>{date}</Text>
                    </View>
                } topDivider />}
                {startDate &&
                <Item title={
                    <View style={commonStyles.row}>
                        <Text style={commonStyles.subText}>{`${_tr('Start date')}: `}</Text>
                        <Text style={commonStyles.supplierAppText}>{startDate}</Text>
                    </View>
                } topDivider />}
                {planningsDate &&
                <Item title={
                    <View style={commonStyles.row}>
                        <Text style={commonStyles.subText}>{`${_tr('Planning date')}: `}</Text>
                        <Text style={commonStyles.supplierAppText}>{planningsDate}</Text>
                    </View>
                } topDivider />}
                {indicationAmount &&
                <Item title={
                    <View style={commonStyles.row}>
                        <Text style={commonStyles.subText}>{`${_tr('Indication amount')}: `}</Text>
                        <Text style={commonStyles.supplierAppText}>{indicationAmount}</Text>
                    </View>
                } topDivider />}
                {lastSupplierUpdate && (
                    <TitledItem topDivider title={_tr('Last update by supplier') + ": " + lastSupplierUpdate.creationDate}>
                        <View style={commonStyles.row}>
                            <Text style={commonStyles.supplierAppText}>{SupplierStatuses[lastSupplierUpdate.status].emoji} {SupplierStatuses[lastSupplierUpdate.status].label()}</Text>
                        </View>
                        <View>
                            <Text style={commonStyles.supplierAppText}>{lastSupplierUpdate.description}</Text>
                        </View>
                    </TitledItem>
                )}
                {workAssignment.hasViewpoint && (
                    <TitledItem>
                        <Button title={_tr('Open viewpoint in BIM model')}
                                type="outline" raised
                                onPress={() => {
                            const url = `/extern/leverancier/current/request/${workAssignment.requestId}/viewer/`;
                            if (Platform.OS === 'web') {
                                window.open(url, '_blank');
                            } else {
                                Linking.openURL(url);
                            }
                        }} />
                    </TitledItem>
                )}
            </View>

            { !isSubmitted && (
                <React.Fragment>
                    <BlockTitle style={blockHeaderStyles.darkWithPadding}>{_tr('Report the status of the work')}</BlockTitle>

                    <View>
                        <TitledItem title={_tr('Status')}>
                            <Select value={draftUpdateStatus} onChange={e => setDraftUpdateStatus(e.currentTarget.value)}>
                                {Object.entries(SupplierStatuses).map(([ status, { emoji, label }]) => (
                                    <SelectOption value={status} key={status}>
                                        {emoji} {label()}
                                    </SelectOption>
                                ))}
                            </Select>
                        </TitledItem>
                        { draftUpdateStatus !== 'COMPLETE' && (
                            <TitledItem title={_tr('Description')}>
                                <TextInput placeholder={_tr('Please explain the problem, or the action that needs to be done')}
                                           value={draftUpdateDescription || ''}
                                           onChange={e => setDraftUpdateDescription(e.currentTarget.value)}
                                           multiline={true}
                                           numberOfLines={4} />
                            </TitledItem>
                        )}
                    </View>

                    <TitledItem>
                        <Button title={_trf("Report work order status as '%s'", SupplierStatuses[draftUpdateStatus].label().toLowerCase())}
                                onPress={onFinishedByDealerPress} />
                    </TitledItem>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export const SupplierStatuses = {
    COMPLETE: {
        emoji: '✔️',
        label: () => _trc('WorkOrderSupplierUpdateStatus', 'COMPLETE')
    },
    FOLLOW_UP_ACTION_NEEDED: {
        emoji: '➡️',
        label: () => _trc('WorkOrderSupplierUpdateStatus', 'FOLLOW_UP_ACTION_NEEDED')
    },
    BLOCKED: {
        emoji: '🛑',
        label: () => _trc('WorkOrderSupplierUpdateStatus', 'BLOCKED')
    }
};

const styles = StyleSheet.create({
    itemContainer: {
        paddingVertical: '7px',
        paddingHorizontal: '15px',
        height: '45px'
    }
});
