import React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import RequestAppHome from './request-app/Home';
import Details from './request-app/details/Details';
import VveDetails from './request-app/details/VveDetails';
import DebtorDetails from './request-app/details/DebtorDetails';
import ReportCreate from './request-app/reports/ReportCreate';
import {defaultTheme, defaultOptions} from './style/defaultStyles';
import Reports from './request-app/reports/Reports';
import ReportDetails from './request-app/reports/ReportDetails';
import HeaderButton from './components/HeaderButton';
import {navigatorTo} from './utils/reactUtils';
import SupplierAppHome from './supplier-app/layout/Home';
import {connect} from 'react-redux';
import {View} from 'react-native';
import WorkAssignmentDetails from './supplier-app/feature/workAssignment/WorkAssignmentDetails';
import InspectionDetails from './supplier-app/feature/inspection/InspectionDetails';
import InspectionLocationDetails from './supplier-app/feature/inspection/InspectionLocationDetails';
import InspectionElementDetails from './supplier-app/feature/inspection/InspectionElementDetails';
import {Icon, ThemeProvider} from 'react-native-elements';
import {supplierAppTheme, supplierAppComponentsTheme} from './style/supplierAppStyles';
import BIMDetails from "./request-app/details/BIMDetails";
import {TenantDetails} from "./request-app/details/TenantDetails";

const Stack = createStackNavigator();

const getAppTheme = appType => {
    switch (appType) {
        case 'supplier-app':
            return supplierAppTheme;
        default:
            return defaultTheme;
    }
};

const AppStack = ({appType}) => (
    <NavigationContainer theme={getAppTheme(appType)}>
        {appType === 'request-app'
            ? <RequestAppStack />
            : appType === 'supplier-app'
                ? <SupplierAppStack />
                : <EmptyStack />}
    </NavigationContainer>
);

const RequestAppStack = () => (
    <Stack.Navigator initialRouteName="home" screenOptions={defaultOptions()}>
        <Stack.Screen name="home" component={RequestAppHome} />
        <Stack.Screen name="details" component={Details} />
        <Stack.Screen name="bimDetails" component={BIMDetails} />
        <Stack.Screen name="tenantDetails" component={TenantDetails} />
        <Stack.Screen name="vveDetails" component={VveDetails}
            options={({route}) => ({headerTitle: route.params.title || _tr('VvE Details')})} />
        <Stack.Screen name="debtorDetails" component={DebtorDetails} />
        <Stack.Screen name="createReport" component={ReportCreate} />
        <Stack.Screen name="recentReports" component={Reports} options={({navigation}) => ({
            headerTitle: _tr('Requests'),
            headerStyle: {...defaultOptions().headerStyle, borderBottomWidth: 0},
            headerRight: () =>
                <HeaderButton
                    title={_tr('Create a request')}
                    icon={<Icon
                        name="add"
                        color="white"
                        size={20}
                        iconStyle={{ paddingRight: 5 }}
                    />}
                    onPress={navigatorTo('createReport', navigation)} />
        })} />
        <Stack.Screen name="reportDetails" component={ReportDetails}
            options={({route}) => ({headerTitle: `${_tr('Request')} ${route.params.report.nr}`})} />
    </Stack.Navigator>
);

const SupplierAppStack = () => (
    <ThemeProvider theme={supplierAppComponentsTheme}>
        <Stack.Navigator initialRouteName="home" screenOptions={defaultOptions()}>
            <Stack.Screen name="home" component={SupplierAppHome} />
            <Stack.Screen name="workAssignmentDetails" component={WorkAssignmentDetails} />
            <Stack.Screen name="inspectionDetails" component={InspectionDetails} />
            <Stack.Screen name="InspectionLocationDetails" component={InspectionLocationDetails} />
            <Stack.Screen name="InspectionElementDetails" component={InspectionElementDetails} />
        </Stack.Navigator>
    </ThemeProvider>
);

const EmptyStack = () => (
    <Stack.Navigator initialRouteName="empty-screen" screenOptions={defaultOptions}>
        <Stack.Screen name="empty-screen">
            {() => <View />}
        </Stack.Screen>
    </Stack.Navigator>
);

const mapStateToProps = state => state.loginPage;

export default connect(mapStateToProps)(AppStack);