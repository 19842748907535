import React from 'react';
import {View, StyleSheet, Text} from 'react-native';
import aspectStatusStyles from '../../../style/aspectStatus';
import commonStyles from '../../../style/common';

const filterWithState = (elements, statePerElement, state) => {
    return elements.filter(element => {
        const elemState = statePerElement.find(st => st.element === element.id);
        if (elemState) {
            return elemState.state === state;
        } else {
            return state === 'MISSING';
        }
    });
};

export default ({elements, statePerElement}) => {
    return (
        <View style={[commonStyles.row, commonStyles.fullWidth, styles.container]}>
            {['MISSING', 'GREAT', 'WARN', 'BAD'].map(state => {
                const num = filterWithState(elements, statePerElement, state).length;
                return num > 0 ? (
                    <View key={state} style={[styles.statusCounter, aspectStatusStyles[state]]} >
                        <Text>{num}</Text>
                    </View>
                ) : null;
            })}
        </View>
    );
};

const styles = StyleSheet.create({
    statusCounter: {
        paddingLeft: '.6rem',
        paddingVertical: '.25rem',
        width: '2rem',
        height: '2rem',
        borderRadius: '1rem',
        marginRight: '.25rem',
        display: 'inline-block',
    },
    container: {
        flexWrap: 'wrap'
    }
});
