import React from 'react';
import RequestListItem from "./RequestListItem";
import {ScrollView, Text} from 'react-native';
import commonStyles from '../../../style/common';

export default ({requests}) => {
    if (requests.length > 0) {
        return (
            <ScrollView style={commonStyles.fullSize}>
                {requests.map(request =>
                    <RequestListItem
                        key={request.id}
                        request={request} />)}
            </ScrollView>
        );
    } else {
        return <Text>{_tr('No requests')}</Text>
    }
};