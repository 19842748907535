import {defaultTheme, headerBackgroundColor} from "./defaultStyles";

export const supplierAppTheme = {
    ...defaultTheme,
    colors: {
        ...defaultTheme.colors,
        background: '#EFEFF4'
    }
};

export const supplierAppComponentsTheme = {
    ListItem: {
        containerStyle: {
            backgroundColor: 'white'
        }
    }
};

export const chevronTheme = {
    containerStyle: {
        backgroundColor: headerBackgroundColor,
        borderRadius: '50%',
        width: '2rem',
        height: '2rem',
        justifyContent: "center",
        alignItems: "center"
    },
    iconProps: {
        size: 26,
        color: 'white'
    }
};
