import {configureStore, combineReducers, getDefaultMiddleware} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

// Import & combine reducers
import loginPageReducer from './login/LoginPageSlice';
import loginFormReducer from './login/LoginFormSlice';
import mainMenuPanelReducer from './supplier-app/layout/MainMenuPanelSlice';
import filesReducer from './supplier-app/feature/file/filesReducer';
import detailsReducer from './request-app/details/DetailsSlice';
import vveDetailsReducer from './request-app/details/VveDetailsSlice';
import bimDetailsReducer from './request-app/details/bimDetailsSlice';
import tenantDetailsReducer from './request-app/details/tenantDetailsSlice';
import ReportCreateReducer from './request-app/reports/ReportCreateSlice';
import reportsReducer from './request-app/reports/ReportsSlice';
import contractsReducer from './supplier-app/feature/contract/ContractSlice';
import workAssignmentsReducer from './supplier-app/feature/workAssignment/WorkAssignmentsSlice';
import workAssignmentReducer from './supplier-app/feature/workAssignment/WorkAssignmentSlice';
import inspectionsReducer from './supplier-app/feature/inspection/InspectionsSlice';
import inspectionReducer from './supplier-app/feature/inspection/InspectionSlice';
import schedulesReducer from './supplier-app/feature/schedule/SchedulesSlice';

const rootReducer = combineReducers({
    loginPage: loginPageReducer,
    loginForm: loginFormReducer,
    mainMenuPanel: mainMenuPanelReducer,
    files: filesReducer,
    details: detailsReducer,
    vveDetails: vveDetailsReducer,
    bimDetails: bimDetailsReducer,
    tenantDetails: tenantDetailsReducer,
    reportCreate: ReportCreateReducer,
    reports: reportsReducer,
    contracts: contractsReducer,
    workAssignments: workAssignmentsReducer,
    selectedWorkAssignment: workAssignmentReducer,
    inspections: inspectionsReducer,
    selectedInspection: inspectionReducer,
    schedules: schedulesReducer
});

// Configure store
export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
        .concat(thunk)
});
