import React, {useRef, useEffect} from 'react';
import {getFileThumbnailUrl, getFileDownloadUrl} from '../../../utils/FileUtils';
import {ActivityIndicator, View, Image} from 'react-native';
import Link from '../../../components/Link';

const FileThumbnailFetching = ({file, size}) => {
    return (
        <View>
            <ActivityIndicator size={size} />
        </View>
    );
};

const FileThumbnailUploading = ({file, size}) => {
    const imgRef = useRef(null);
    const jsFile = file.jsFile;

    useEffect(() => {
        if (jsFile.type.startsWith('image/')) {
            const url = URL.createObjectURL(jsFile);
            imgRef.current.src = url;
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [file]);

    return (
        <View>
            <ActivityIndicator />
            <Image
                ref={imgRef}
                style={{width: size, height: size}}
                accessibilityLabel={file.jsFile.name} />
        </View>
    );
};

const FileThumbnailReady = ({file, size}) => {
    const dbFile = file.file;
    const src = getFileThumbnailUrl(file, size);
    const link = getFileDownloadUrl(file);

    return (
        <Link href={link} target="_blank">
            <Image
                source={src}
                style={{width: size, height: size}}
                accessibilityLabel={dbFile.name} />
        </Link>
    );
};

export const FileThumbnail = ({file, size = 128}) => {
    if (file.isFetching) {
        return <FileThumbnailFetching file={file} size={size} />;
    } else if (file.isUploading) {
        return <FileThumbnailUploading file={file} size={size} />
    } else {
        return <FileThumbnailReady file={file} size={size} />;
    }
};
