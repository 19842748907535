import React from "react";
import ScheduleListItem from "./ScheduleListItem";
import {Text, ScrollView} from "react-native";
import commonStyles from "../../../style/common";

export default ({schedules}) => {
    if (schedules.length > 0) {
        return (
            <ScrollView style={commonStyles.fullSize}>
                {schedules.map(schedule =>
                    <ScheduleListItem key={schedule.id} schedule={schedule} />)}
            </ScrollView>
        );
    } else {
        return <Text>{_tr('There are no related tasks.')}</Text>
    }
};