import React from "react";
import WorkAssignmentListItem from "./WorkAssignmentListItem";
import {Text, ScrollView} from "react-native";
import commonStyles from "../../../style/common";

export default ({workAssignments}) => {
    if (workAssignments && workAssignments.length > 0) {
        return (
            <ScrollView style={commonStyles.fullSize}>
                {workAssignments.map(workAssignment =>
                    <WorkAssignmentListItem
                        key={workAssignment.id}
                        workAssignment={workAssignment} />)
                }
            </ScrollView>
        );
    } else {
        return <Text>{_tr('There are no work orders.')}</Text>
    }
};
