import React from 'react';
import {Image, StyleSheet} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {getFileThumbnailUrl, isImage} from '../utils/FileUtils';
import commonStyles from '../style/common';

/**
 * Scrollable view that renders the images among the given files.
 * 
 * @param {object[]} files - Array of files to display.
 * @param {number} [200] imageSize - Size of an image.
 * @param {boolean} [true] horizontal - Whether the view shows the images horizontally.
 */
export default ({files, imageSize = 200, horizontal = true, imageContainerStyle, imageStyle, ...props}) => (
    <ScrollView {...props} horizontal={horizontal} style={{...StyleSheet.flatten(styles.imageContainer), ...imageContainerStyle}}>
        {files.map(file =>
            isImage(file) ?
                <Image
                    key={file.id}
                    source={getFileThumbnailUrl(file, imageSize)}
                    style={{
                        ...StyleSheet.flatten(commonStyles.smallMargin),
                        ...{width: imageSize, height: imageSize},
                        ...imageStyle
                    }} />
                : null
        )}
    </ScrollView>
);

const styles = StyleSheet.create({
    imageContainer: {
        flexDirection: 'row',
        width: '100%'
    }
});
