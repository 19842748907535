import React, {useEffect} from 'react';
import {View, Text} from 'react-native';
import {BlockTitle} from '../../components/BlockTitle';
import {ListItem} from 'react-native-elements';
import {ScrollView} from 'react-native-gesture-handler';
import commonStyles from '../../style/common';
import {ActivityIndicator, StyleSheet} from 'react-native-web';
import {connect} from 'react-redux';
import {vveDetailsSlice} from './VveDetailsSlice';
import {navigatorTo} from '../../utils/reactUtils';
import {formatCurrency} from "../../formatCurrency";

const VveDetails = ({route, navigation, loaded, data, dispatch, didInvalidate}) => {
    const {vve, debiteur, boekjaren, isTenant, rentalContracts} = data;
    const {vveId} = route.params;

    const userIsDebtor = (loaded && debiteur !== null);

    useEffect(() => {
        dispatch(vveDetailsSlice.actions.fetch({vveId}));
    }, [vveId, didInvalidate]);

    if (!loaded) return <ActivityIndicator size='large' />;

    let totaal = 0.0, totaalMaand = 0.0;

    if (boekjaren) {
        for (let i = 0; i < boekjaren.length; ++i) {
            boekjaren[i].begrotingen.map((begroting) => {
                if (vve.actieveBegrotingIds.indexOf(begroting.id) !== -1) {
                    begroting.regels.map((regel) => {
                        totaal += regel.totaal;
                        totaalMaand += regel.totaalPerMaand;
                    });
                }
            });
        }
    }

    let debiteurSaldo, debiteurSaldoLabel;
    if (userIsDebtor && !!debiteur) {
        debiteurSaldo = debiteur.saldo;
        if (debiteur.saldo < 0.0) {
            debiteurSaldoLabel = _tr('Due balance');
            debiteurSaldo *= -1.0;
        } else {
            debiteurSaldoLabel = _tr('Ahead of payment');
        }
    }

    return (
        <ScrollView>
            {!!vve.omschrijving &&
                <>
                    <BlockTitle>{_tr('Description')}</BlockTitle>
                    <Text>{vve.omschrijving}</Text>
                </>
            }

            {!vve.financieelBeheer && <Text style={commonStyles.smallMargin}>{_tr('No financial information available.')}</Text>}

            {userIsDebtor &&
                <View style={styles.financialContainer}>
                    <ListItem title={_tr('Total contribution')} rightTitle={`€ ${totaal}`}/>
                    {!!debiteur && <ListItem title={debiteurSaldoLabel} rightTitle={`€ ${debiteurSaldo}`} topDivider bottomDivider />}
                    <ListItem title={_tr('Monthly contribution')} rightTitle={`€ ${totaalMaand}`} bottomDivider />
                </View>
            }

            {userIsDebtor && (
                <View>
                    {vve.financieelBeheer && <ListItem title={_tr('View financial details')} onPress={navigatorTo('debtorDetails', navigation, {vveId})} bottomDivider />}
                </View>
            )}
            {userIsDebtor && (
                <View style={styles.meetingContainer}>
                    <BlockTitle withMargin>{_tr('Meeting date')}</BlockTitle>
                    {!vve.vergaderingen.length && <ListItem title={_tr('No planned meetings')} topDivider bottomDivider />}
                    {vve.vergaderingen.map((vergadering, i) =>
                        (<ListItem key={`${vergadering}-${i}`} title={vergadering} topDivider={i === 0} bottomDivider />))}
                </View>
            )}

            {/*{isTenant && (*/}
            {/*    <View style={styles.financialContainer}>*/}
            {/*        <BlockTitle withMargin>{_tr('Rental contracts')}</BlockTitle>*/}
            {/*        {rentalContracts.map((rentalContract, i) => (*/}
            {/*            <ListItem key={rentalContract.id} title={rentalContract.landlordString} rightTitle={`€ ${formatCurrency(rentalContract.gross)}`} topDivider onPress={navigatorTo('rentalContractDetails', navigation, {vveId, rentalContractId: rentalContract.id})} />*/}
            {/*        ))}*/}
            {/*    </View>*/}
            {/*)}*/}


        </ScrollView>
    );
}

const styles = StyleSheet.create({
    financialContainer: {
        marginTop: 20
    },
    meetingContainer: {
        marginTop: 10
    }
});

const mapStateToProps = state => ({
    ...state.vveDetails,
    data: {
        vve: state.vveDetails.data.vve.data,
        debiteur: state.vveDetails.data.debiteur.data,
        boekjaren: state.vveDetails.data.boekjaren.data,
        isTenant: state.vveDetails.data.isTenant.data,
        rentalContracts: state.vveDetails.data.rentalContracts?.data
    }
});

export default connect(mapStateToProps)(VveDetails);
