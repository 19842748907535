import React from 'react';
import {View, Text} from 'react-native';
import {ListItem} from 'react-native-elements';
import {navigatorTo} from '../../../utils/reactUtils';
import {useNavigation} from '@react-navigation/native';
import commonStyles from '../../../style/common';
import {SupplierStatuses} from "./WorkAssignmentForm";
import {chevronTheme} from "../../../style/supplierAppStyles";

export default ({workAssignment}) => {
    const {vve, omschrijving: description, aanmaakdatum: date, reparatiePrioriteit} = workAssignment;
    const navigation = useNavigation();

    const priority = reparatiePrioriteit && reparatiePrioriteit.naam;

    const vveName = vve.toString;

    return (
        <ListItem
            onPress={navigatorTo('workAssignmentDetails', navigation, {workAssignmentId: workAssignment.id})}
            title={
                <View>
                    <Text style={commonStyles.heavyText}>{description}</Text>
                    <Text style={commonStyles.supplierAppText}>{date}</Text>
                    <Text style={commonStyles.supplierAppText}>{priority}</Text>
                    {workAssignment.afgerond ? (
                        <Text style={commonStyles.supplierAppText}>
                            {_tr('Handled')}
                        </Text>
                    ) : (workAssignment.lastSupplierUpdate && (
                        <Text style={commonStyles.supplierAppText}>
                            {SupplierStatuses[workAssignment.lastSupplierUpdate.status].emoji}{' '}
                            {SupplierStatuses[workAssignment.lastSupplierUpdate.status].label()}
                        </Text>
                    ))}
                    <Text style={commonStyles.subText}>{vveName}</Text>
                </View>
            }
            chevron={chevronTheme}
            topDivider />
    );
};
