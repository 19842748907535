import {createFetchableSlice} from '../../utils/createFetchableSlice';
import {setToPayload} from '../../utils/reduxUtils';
import {fetchRecentReports, fetchPersonalReports} from '../../Api';

export const reportsSlice = createFetchableSlice({
    name: 'reports',
    fetchable: [
        {name: 'recent', api: fetchRecentReports},
        {name: 'personal', api: fetchPersonalReports}
    ],
    reducers: {
        setFilter: (state, action) => ({...state, data: {...state.data, filter: action.payload}})
    }
});

export const {setFilter} = reportsSlice.actions;

export default reportsSlice.reducer;