import React, {useState} from 'react';
import {connect} from "react-redux";
import {View, ActivityIndicator} from 'react-native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {BlockTitle} from '../../components/BlockTitle';
import ContractList from '../feature/contract/ContractList';
import {contractSlice} from '../feature/contract/ContractSlice';
import {workAssignmentsSlice} from '../feature/workAssignment/WorkAssignmentsSlice';
import {inspectionsSlice} from '../feature/inspection/InspectionsSlice';
import {defaultOptions} from '../../style/defaultStyles';
import WorkAssignmentList from '../feature/workAssignment/WorkAssignmentList';
import InspectionList from '../feature/inspection/InspectionList';
import ScheduleList from '../feature/schedule/ScheduleList';
import {schedulesSlice} from '../feature/schedule/SchedulesSlice';
import commonStyles from '../../style/common';
import HeaderButton from '../../components/HeaderButton';
import {Icon, Text} from 'react-native-elements';
import MainMenuPanel from './MainMenuPanel';
import {mainMenuPanelSlice} from './MainMenuPanelSlice';
import ScrollableView from '../../components/ScrollableView';
import {useEffectOnce} from '../../utils/reactUtils';
import AsyncStorage from '@react-native-community/async-storage';
import {Button} from 'react-native-elements';

const TAB_STORAGE_KEY = 'latestTab';

const Tab = createMaterialTopTabNavigator();

const Home = ({dispatch, navigation, contracts, contractsLoaded, workAssignments, workAssignmentsLoaded, inspections, inspectionsLoaded, schedules, schedulesLoaded, appRoles}) => {
    const [loadedInitialTab, setLoadedInitialTab] = useState(false);
    const [initialTabName, setInitialTabName] = useState('contracts');
    const [contractsPage, setContractsPage] = useState(1);
    const [workAssignmentsPage, setWorkAssignmentsPage] = useState(1);
    const [inspectionsPage, setInspectionsPage] = useState(1);
    const [schedulesPage, setSchedulesPage] = useState(1);

    useEffectOnce(() => {
        navigation.setOptions({
            headerRight: () => <HeaderButton onPress={() => dispatch(mainMenuPanelSlice.actions.toggle())} title={<Icon name='menu' />} />
        });

        dispatch(contractSlice.actions.fetchIfNeeded(contractsPage));
        dispatch(workAssignmentsSlice.actions.fetchIfNeeded(workAssignmentsPage));
        dispatch(inspectionsSlice.actions.fetchIfNeeded(inspectionsPage));
        dispatch(schedulesSlice.actions.fetchIfNeeded(schedulesPage));

        const loadInitialTab = async () => {
            try {
                const savedTabName = await AsyncStorage.getItem(TAB_STORAGE_KEY);

                if (savedTabName) {
                    setInitialTabName(savedTabName);
                }
            } finally {
                setLoadedInitialTab(true);
            }
        }

        if (!loadedInitialTab) {
            loadInitialTab();
        }
    });

    const onTabPress = e => {
        const tabName = e.target.split('-')[0];

        AsyncStorage.setItem(TAB_STORAGE_KEY, tabName);
    };

    if (!loadedInitialTab) return <ActivityIndicator size='large' />;

    const doneInspections = inspections && inspections.filter(i => !i.draft);
    const draftInspections = inspections && inspections.filter(i => i.draft);

    const increasePage = (dispatch, setPage, prevValue, slice) => {
        dispatch(slice.actions.invalidate());
        setPage(prevValue+1);
        dispatch(slice.actions.fetchIfNeeded(prevValue+1));
    }

    const decreasePage = (dispatch, setPage, prevValue, slice) => {
        dispatch(slice.actions.invalidate());
        setPage(prevValue-1);
        dispatch(slice.actions.fetchIfNeeded(prevValue-1));
    }

    const PagedBlockTitle = ({dispatch, appRoles, adminText, userText, loaded, data, currentPage, setPage, slice}) => {
        return <BlockTitle withMargin>
            { appRoles.includes("administrator") ? 
              <Text>{adminText}   </Text>
            : <Text>{userText}   </Text>
            }
            { loaded && currentPage > 1 &&
                <Button title={'<'} onPress={() => decreasePage(dispatch, setPage, currentPage, slice)} type='clear' style={commonStyles.loginButton}/>
            }
            { loaded && (currentPage != 1 || (data != null && data.length == 50)) &&
                <Text> {currentPage} </Text>
            }
            { data != null && data.length == 50 &&
                <Button title={'>'} onPress={() => increasePage(dispatch, setPage, currentPage, slice)} type='clear' style={commonStyles.loginButton} />
            }
        </BlockTitle>
    }
    const ContractsTab = () => (
        <View>
            <PagedBlockTitle dispatch={dispatch} appRoles={appRoles}
                adminText={_tr('All contracts')} userText={_tr('Your contracts with the administrator')}
                loaded={contractsLoaded} data={contracts}
                currentPage={contractsPage} setPage={setContractsPage}
                slice={contractSlice} />

                <ScrollableView>
                    {(!contractsLoaded || !contracts) ?
                        <ActivityIndicator size='large' />
                        : <ContractList contracts={contracts} />}
                </ScrollableView>
        </View>
    );

    const WorkAssignmentsTab = () => (
        <View>
            <PagedBlockTitle dispatch={dispatch} appRoles={appRoles}
                adminText={_tr('All work assignments')} userText={_tr('Your work assignments')}
                loaded={workAssignmentsLoaded} data={workAssignments}
                currentPage={workAssignmentsPage} setPage={setWorkAssignmentsPage}
                slice={workAssignmentsSlice} />

            <ScrollableView>
                {(!workAssignmentsLoaded || !workAssignments)
                    ? <ActivityIndicator size='large' />
                    : <WorkAssignmentList workAssignments={workAssignments} />}
            </ScrollableView>
        </View>
    );

    const InspectionsTab = () => (
        <View style={commonStyles.fullSize}>
            {(draftInspections && draftInspections.length > 0) &&
                <View>
                    <BlockTitle withMargin>{_tr('Editing')}</BlockTitle>
                    <ScrollableView>
                        {(!inspectionsLoaded || !draftInspections)
                            ? <ActivityIndicator size='large' />
                            : <InspectionList inspections={draftInspections} />}
                    </ScrollableView>
                </View>
            }

            <View>
                <PagedBlockTitle dispatch={dispatch} appRoles={appRoles}
                    adminText={_tr('All tasks')} userText={_tr('Tasks')}
                    loaded={schedulesLoaded} data={schedules}
                    currentPage={schedulesPage} setPage={setSchedulesPage}
                    slice={schedulesSlice} />

                <ScrollableView>
                    {(contractsLoaded && (contracts.length === 0 && contractsPage === 1))
                        ? <Text>{_tr('No contract found.')}</Text>
                        : (!schedulesLoaded || !schedules)
                            ? <ActivityIndicator size='large' />
                            : <ScheduleList schedules={schedules} />
                    }
                </ScrollableView>
            </View>

            <View>
                <PagedBlockTitle dispatch={dispatch} appRoles={appRoles}
                    adminText={_tr('All performed inspections')} userText={_tr('Performed inspections')}
                    loaded={inspectionsLoaded} data={doneInspections}
                    currentPage={inspectionsPage} setPage={setInspectionsPage}
                    slice={inspectionsSlice} />

                <ScrollableView>
                    {(!inspectionsLoaded || !doneInspections)
                        ? <ActivityIndicator size='large' />
                        : <InspectionList inspections={doneInspections} />
                    }
                </ScrollableView>
            </View>
        </View>
    );

    return (
        <View style={commonStyles.row}>
            <Tab.Navigator initialRouteName={initialTabName} screenOptions={defaultOptions()} >
                <Tab.Screen name="contracts" component={ContractsTab} options={() => tabLabel(_tr('Contracts'))} listeners={{tabPress: onTabPress}} />
                <Tab.Screen name="workAssignments" component={WorkAssignmentsTab} options={() => tabLabel(_tr('Work orders'))} listeners={{tabPress: onTabPress}} />
                <Tab.Screen name="inspections" component={InspectionsTab} options={() => tabLabel(_tr('Inspections'))} listeners={{tabPress: onTabPress}} />
            </Tab.Navigator>
            <MainMenuPanel />
        </View>
    );
};

const tabLabel = label => ({
    tabBarLabel: () => (
        <Text style={{ fontSize: 17, fontWeight: '600', textTransform: 'none' }}>
            {label}
        </Text>
    )
});


const mapStateToProps = state => ({
    contracts: state.contracts.data,
    contractsLoaded: state.contracts.loaded,
    workAssignments: state.workAssignments.data,
    workAssignmentsLoaded: state.workAssignments.loaded,
    inspections: state.inspections.data,
    inspectionsLoaded: state.inspections.loaded,
    schedules: state.schedules.data,
    schedulesLoaded: state.schedules.loaded,
    appRoles: state.loginPage.appRoles
});

export default connect(mapStateToProps)(Home);