import React, {useEffect} from "react";
import {ListItem} from "react-native-elements";
import {chevronTheme} from "../../style/supplierAppStyles";
import {ScrollView} from "react-native-gesture-handler";
import {ActivityIndicator, Linking, Platform, View} from "react-native";
import {BlockTitle} from "../../components/BlockTitle";
import {StyleSheet} from "react-native-web";
import {connect} from "react-redux";
import {bimDetailsSlice} from "./bimDetailsSlice";

const BIMDetails = ({ navigation, data, loaded, dispatch, didInvalidate }) => {
	useEffect(() => {
		dispatch(bimDetailsSlice.actions.fetch());
	}, [didInvalidate]);

	return (
		<ScrollView>
			{!loaded && <ActivityIndicator size='large' />}
			{loaded &&
			<View>
				{data &&
				<>
					<BlockTitle withMargin>{_tr('3D models')}</BlockTitle>
					<View>
						{data.map((project, i) => (
							<ListItem
								key={project.id}
								title={project.name}
								subtitle={project.complexName}
								onPress={() => {
									const url = `/vve/${project.complexId}/project/${project.id}/?fromApp=true`;
									if (Platform.OS === 'web') {
										const viewerWindow = window.open(url, '_blank');
										viewerWindow.addEventListener('message', event => {
											const message = event.data;
											if (message.type === 'REQUEST_CREATED') {
												navigation.navigate('recentReports');
											}
										});
									} else {
										Linking.openURL(url);
									}
								}}
								topDivider={i === 0}
								bottomDivider
								chevron={chevronTheme} />
						))}
					</View>
				</>
				}
			</View>
			}
		</ScrollView>
	);
};

const mapStateToProps = state => state.bimDetails;

export default connect(mapStateToProps)(BIMDetails);
