import React from 'react';
import {TextInput, StyleSheet} from 'react-native';

export default props => {
    return (
        <TextInput multiline {...props} style={[styles.textArea, props.style]} />
    );    
};

const styles = StyleSheet.create({
    textArea: {
        width: '100%',
        height: '100%',
        minHeight: '100px',
        paddingHorizontal: '10px',
        backgroundColor: 'white'
    },
});
