import React from 'react';
import {StyleSheet, Text, Linking, Platform, View} from 'react-native';

export default ({children, title, href = '', target = '', ...props}) => {
    const style = [styles.link, props.style];

    if (children) {
        const handlePress = e => {
            href && openURL(href, target);
            props.onPress && props.onPress(e);
        };

        return (
            <View {...props} onPress={handlePress}>
                {React.Children.map(children, child => (
                    React.cloneElement(child, {
                        style: [
                            child.type.name === 'Text' ? styles.link : '',
                            props.style,
                            child.props.style
                        ]
                    })
                ))}
            </View>
        );
    } else if (href) {
        const handlePress = e => {
            openURL(href, target);
            props.onPress && props.onPress(e);
        };

        return (
            <Text {...props} style={style} onPress={handlePress}>{title}</Text>
        );
    } else {
        return (
            <Text {...props} style={style}>{title}</Text>
        );
    }
};

const openURL = (url, target) => {
    if (Platform.OS === 'web') {
        window.open(url, target);
    } else {
        Linking.openURL(url);
    }
};

const styles = StyleSheet.create({
    link: {
        fontSize: '17px',
        color: '#007aff',
        cursor: 'pointer'
    }
});
