import {apiUrl} from './constants';
import {getData, getDataRequest, postData, postDataRequest} from './utils/ajax';

const loginUrl = `${apiUrl}/login`;
const logoutUrl = `${apiUrl}/logout`;
const statusUrl = `${apiUrl}/status`;
const appTypeUrl = `${apiUrl}/info/app-type`;
const detailsUrl = `${apiUrl}/details`;
const bimDetailsUrl = `${apiUrl}/details/projects`;
const vveDetailsUrl = `${apiUrl}/details/vve`;
const debiteurUrl = `${apiUrl}/debiteur`;
const boekjarenUrl = `${apiUrl}/boekjaren`;
const reportsUrl = `${apiUrl}/reports`;
const reportImageUploadUrl = `${reportsUrl}/image`;
const recentReportsUrl = `${reportsUrl}/recent`;
const personalReportsUrl = `${reportsUrl}/personal`;
const contractsUrl = page =>`${apiUrl}/supplier/contracts/page/${page}`;
const workAssignmentsUrl = `${apiUrl}/workassignments`;
const workAssignmentsPageUrl = page => `${workAssignmentsUrl}/page/${page}`;
const workAssignmentUrl = id => `${workAssignmentsUrl}/${id}`;
const relatedInspectionsUrl = workAssignmentId => `${apiUrl}/inspections/by-workassignment/${workAssignmentId}`;
const inspectionsUrl = `${apiUrl}/inspections`;
const inspectionsPageUrl = page => `${inspectionsUrl}/page/${page}`;
const inspectionUrl = id => `${inspectionsUrl}/${id}`;
const inspectionAspectElementsUrl = inspection => `${apiUrl}/aspect/${inspection.aspect.id}/elements`;
const inspectionWorkAssignmentsUrl = inspectionId => `${apiUrl}/workassignments/by-inspection/${inspectionId}`;
const inspectionRequestsUrl = inspectionId => `${apiUrl}/requests/by-inspection/${inspectionId}`;
const schedulesUrl = page => `${apiUrl}/aspect-schedules/page/${page}`;

const jsonHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};

const jsonResponse = response => response.json();
const textResponse = response => response.text();

const getBase64 = file => {
	return new Promise((resolve, reject) => {
		if (!file) {
			resolve();
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result.replace(/^data:image\/(png|jpg|jpeg|bmp);base64,/, ""));
		};
		reader.onerror = error => {
			console.log('Error: ', error);
		};
	});
};

export const performLogin = ({username, password}) => {
	return postDataRequest(loginUrl, `username=${username}&password=${password}&remember-me=on&_spring_security_remember_me=on`, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	});
}

export const performLogout = () => {
	return getDataRequest(logoutUrl, {credentials: 'include'});
}

export const fetchStatus = () => {
	return getData(statusUrl, {mode: 'cors', credentials: 'include'});
}

export const fetchAppType = () => {
	return getData(appTypeUrl, {mode: 'cors', credentials: 'include'});
}

export const fetchAppRoles = async () => {
	try {
		return await getData(`${apiUrl}/info/app-roles`);
	} catch (e) {
		// Backwards compatibility (can be removed later)
		console.warn("Could not get app roles", e);
		return null;
	}
}

export const fetchDetails = () => {
	return getData(detailsUrl, {mode: 'cors', credentials: 'include'});
}

export const fetchBimDetails = () => {
	return getData(bimDetailsUrl, {mode: 'cors', credentials: 'include'});
}

export const fetchVveDetails = ({vveId}) => {
	return getData(`${vveDetailsUrl}/${vveId}`, {mode: 'cors', credentials: 'include'});
}

export const fetchDebiteur = ({vveId}) => {
	return getData(`${debiteurUrl}/${vveId}`, {credentials: 'include'});
}

export const fetchDebiteurIfUserIsDebtor = ({vveId}) => {
	return getData(`${debiteurUrl}/${vveId}/is-debiteur`, {credentials: 'include'})
		.then(async res => {
			const {isDebtor} = res;

			if (isDebtor) {
				return fetchDebiteur({vveId});
			}

			return null;
		});
}

export const fetchIsTenant = ({vveId: complexId}) => {
	return getData(`${apiUrl}/tenant/complex/${complexId}/is-tenant`);
};

export const fetchRentalContracts =  ({vveId: complexId}) => {
	return getData(`${apiUrl}/tenant/complex/${complexId}/contracts`);
};

export const fetchTenantDetails = async () => {
	const details = await fetchDetails();
	const complexIds = Object.keys(details.vves);
	const rentalContracts = (await Promise.all(complexIds.map(complexId => fetchRentalContracts({ vveId: complexId }))))
		.flat();

	return { complexIds, rentalContracts };
};

export const fetchBoekjaren = ({vveId}) => {
	return getData(`${boekjarenUrl}/${vveId}`, {credentials: 'include'});
}

export const createReport = ({files, description, publiclyVisible, complex}) => {
	const attachmentIds = files.map(file => file.id);

	const body = JSON.stringify({
		omschrijving: description,
		publiclyVisible,
		attachmentIds,
		vve: complex === '' ? null : complex,
	});

	return postData(reportsUrl, body, {
		mode: 'cors',
		credentials: 'include',
		headers: jsonHeaders
	});
};

export const fetchRecentReports = () => {
	return getData(recentReportsUrl, {credentials: 'include'});
};

export const fetchPersonalReports = () => {
	return getData(personalReportsUrl, {credentials: 'include'});
};

export const uploadReportImage = file => {
	return getBase64(file)
		.then(base64 => (
			fetch(reportImageUploadUrl, {credentials: 'include', method: 'POST', body: base64})
				.then(jsonResponse)
		));
};

export const fetchContracts = (page) => {
	return getData(contractsUrl(page));
};

export const fetchWorkAssignments = (page) => {
	return getData(workAssignmentsPageUrl(page));
};

export const fetchWorkAssignment = id => {
	return getData(workAssignmentUrl(id));
};

export const fetchRelatedInspection = workAssignmentId => {
	return getData(relatedInspectionsUrl(workAssignmentId));
};

export const postWorkAssignmentCompleted = (id, supplierUpdate) => {
	return postData(`${workAssignmentUrl(id)}/supplier-status`, supplierUpdate);
};

export const fetchInspections = (page) => {
	return getData(inspectionsPageUrl(page));
};

export const fetchInspection = inspection => {
	return getData(inspectionUrl(inspection.id));
};

export const fetchInspectionAspectElements = inspection => {
	return getData(inspectionAspectElementsUrl(inspection));
};

export const fetchInspectionWorkAssignments = inspection => {
	return getData(inspectionWorkAssignmentsUrl(inspection.id));
};

export const fetchInspectionRequests = inspection => {
	return getData(inspectionRequestsUrl(inspection.id));
};

export const fetchSchedules = (page) => {
	return getData(schedulesUrl(page));
};
