import {performLogout} from "../Api";
import {loginPageSlice} from "../login/LoginPageSlice";

export const isObject = obj => !!obj && obj.constructor.name === 'Object';

export const isFunction = func => !!func && func.constructor.name === 'Function';

export const capitalize = value => {
    if (typeof value !== "string") throw new TypeError(`Value passed to capitalize should be of type string, but was ${typeof value}`);

    switch (value.length) {
        case 0: return "";
        default: return value[0].toUpperCase() + value.slice(1);
    }
}

export const includesIgnoreCase = (target, string) => {
    if (typeof target !== "string" || typeof string !== "string") return false;

    return string.toLowerCase().includes(target.toLowerCase());
}

export const logout = dispatch => {
    performLogout()
        .finally(() => dispatch(loginPageSlice.actions.open()));
};
