import React, {useState, Component} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import {ListItem, Input, Overlay, SearchBar, Header, Icon} from 'react-native-elements';
import {detailsSlice} from '../details/DetailsSlice';
import {setComplex, toggleShowComplexSelector, togglePubliclyVisible, setDescription, setFiles, resetForm} from './ReportCreateSlice';
import Modal from 'modal-react-native-web';
import {connect} from 'react-redux';
import {includesIgnoreCase} from '../../utils/Util';
import {headerBackgroundColor} from '../../style/defaultStyles';
import FileSelectButton from '../../components/FileSelectButton';
import {createReport, uploadReportImage} from '../../Api';
import HeaderButton from '../../components/HeaderButton';
import {reportsSlice} from './ReportsSlice';
import commonStyles from '../../style/common';
import {chevronTheme} from "../../style/supplierAppStyles";

class ReportCreate extends Component {
    constructor(props, context) {
        super(props, context);

        this.createReport = this.createReport.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.loadContent = this.loadContent.bind(this);

        props.navigation.setOptions({
            headerRight: () => <HeaderButton title={_tr('Send request')} onPress={this.createReport} />
        });
    }

    componentDidMount() {
        this.loadContent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.didInvalidate !== prevProps.didInvalidate) {
            this.loadContent();
        }
    }

    async loadContent() {
        await this.props.dispatch(detailsSlice.actions.fetchIfNeeded());
        this.setDefaultComplex();
    }

    // hack
    setDefaultComplex() {
        const { dispatch, details } = this.props;
        const vveIds = Object.keys(details.vves);
        if (vveIds.length) {
            dispatch(setComplex(vveIds[0]));
        }
    }

    createReport() {
        const {dispatch, navigation, complex, publiclyVisible, description, files} = this.props;

        if (description) {
            createReport({
                date: null,
                files,
                description,
                publiclyVisible,
                complex,
            })
                .then(() => {
                    dispatch(resetForm());
                    this.setDefaultComplex();
                    dispatch(reportsSlice.actions.invalidate());
                    alert(_tr('Your request has been sent'));
                    navigation.navigate('recentReports');
                })
        } else {
            alert(_tr('No description'));
        }
    };

    selectImage(event) {
        const files = Array.from(event.currentTarget.files);

        Promise.all(files.map(file => uploadReportImage(file)))
            .then(files => {
                this.props.dispatch(setFiles(files));
            });
    }

    render() {
        const {dispatch, complex, showComplexSelector, publiclyVisible, description, details, files} = this.props;

        return (
            <View>
                <FileSelectButton
                    title={_tr('Set photos')}
                    multiple
                    value={files}
                    onSelect={this.selectImage}
                    buttonStyle={styles.addPhotoButton}
                    buttonContainerStyle={styles.addPhotoButtonContainer} />

                <ListItem
                    title={_tr('Select a complex')}
                    rightTitle={details && details.vves[complex]}
                    onPress={() => dispatch(toggleShowComplexSelector())}
                    leftIcon={{name: 'home'}}
                    topDivider bottomDivider chevron={chevronTheme} />
                <VveSelector dispatch={dispatch} isVisible={showComplexSelector} details={details} selectedComplex={complex} />

                <ListItem
                    title={_tr('Share information with fellow residents.')}
                    onPress={() => dispatch(togglePubliclyVisible())}
                    checkBox={{
                        checked: publiclyVisible,
                        onPress: () => dispatch(togglePubliclyVisible())
                    }}
                    bottomDivider />

                <Input label={_tr('Description')} multiline inputStyle={styles.descriptionInput} value={description} onChangeText={text => dispatch(setDescription(text))} />
            </View >
        );
    }
}

const VveSelector = ({dispatch, isVisible, details, selectedComplex}) => {
    const closeSelector = () => dispatch(toggleShowComplexSelector());

    const selectComplex = complex => {
        dispatch(setComplex(complex));
        closeSelector();
    };

    const [search, setSearch] = useState('');

    window.vves = details && details.vves;

    let vves = (details && details.vves && Object.entries(details.vves)) || [];
    if (!!search) {
        vves = vves.filter(([id, name]) => includesIgnoreCase(search, name));
    }

    return (
        <Overlay isVisible={isVisible} fullScreen onBackdropPress={closeSelector} ModalComponent={Modal} overlayStyle={commonStyles.noPadding}>
            <View>
                <Header
                    leftComponent={
                        <Icon name='arrow-back' onPress={closeSelector} color='white' />
                    }
                    centerComponent={
                        <Text style={styles.vveSelectorHeaderText}>{_tr('Select a complex')}</Text>
                    }
                    containerStyle={styles.headerContainer}
                    leftContainerStyle={styles.vveSelectorHeaderBackContainer}
                    centerContainerStyle={styles.vveSelectorHeaderTextContainer} />
                <SearchBar
                    value={search}
                    lightTheme
                    onChangeText={setSearch}
                    onClear={() => setSearch('')}
                    placeholder={_tr("Search for your complex")}
                    containerStyle={styles.vveSelectorSearchbarContainer}
                    inputStyle={styles.vveSelectorSearchbar}
                    inputContainerStyle={commonStyles.whiteBg} />
                {vves.map(([id, name], i) =>
                    <ListItem
                        key={id}
                        title={name}
                        onPress={() => selectComplex(id)}
                        checkmark={id === selectedComplex}
                        topDivider={i === 0}
                        bottomDivider />)}
            </View>
        </Overlay>
    );
};

const styles = StyleSheet.create({
    addPhotoButton: {
       // width: '120px',
        height: '40px',
        borderRadius: '36px'
    },
    addPhotoButtonContainer: {
       // width: '130px',
        paddingHorizontal: '10px',
        margin: '10px'
    },
    descriptionInput: {
        height: '150px'
    },
    headerContainer: {
        height: '64px',
        backgroundColor: headerBackgroundColor
    },
    vveSelectorHeaderTextContainer: {
        alignItems: 'flex-start',
    },
    vveSelectorHeaderText: {
        fontSize: 20,
        fontWeight: '500',
        color: 'white'
    },
    vveSelectorHeaderBackContainer: {
        maxWidth: '50px'
    },
    vveSelectorSearchbar: {
        backgroundColor: 'white',
        color: 'black'
    },
    vveSelectorSearchbarContainer: {
        backgroundColor: 'white',
        marginBottom: '30px',
        shadowColor: "#000",
        shadowOffset: {width: 0, height: 3},
        shadowOpacity: 0.3,
        shadowRadius: 5
    }
});

const mapStateToProps = state => ({...state.reportCreate, details: state.details.data});

export default connect(mapStateToProps)(ReportCreate);