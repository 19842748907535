import {StyleSheet} from "react-native";

export default StyleSheet.create({
    aspectStatus: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    aspectStatusContainer: {
        position: 'relative',
        width: '100%',
        flexGrow: 1
    },
    icon: {
        position: 'absolute',
        width: '10px',
        height: '10px',
        borderRadius: '100%',
        top: '0.4em',
        left: '1.1em'
    },
    GREAT: {
        backgroundColor: 'rgba(85,220,80, 0.6)',
        borderWidth: '1px',
        borderColor: '#55dc50'
    },
    WARN: {
        backgroundColor: 'rgba(220, 182, 85, 0.6)',
        borderWidth: '1px',
        borderColor: '#dca43e'
    },
    BAD: {
        backgroundColor: 'rgba(220, 75, 87, 0.6)',
        borderWidth: '1px',
        borderColor: '#dc4b57'
    },
    MISSING: {
        backgroundColor: '#919191',
        borderWidth: '1px',
        borderColor: '#919191'
    }
});