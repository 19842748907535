import {createFetchableSlice, createDefaultInitialState} from '../../../utils/createFetchableSlice';
import {fetchInspection, fetchInspectionAspectElements, fetchInspectionWorkAssignments, fetchInspectionRequests} from '../../../Api';
import {FileReference, EntityTypes} from '../file/FileReference';
import {apiUrl} from '../../../constants';
import produce from 'immer';
import {uploadFiles} from '../file/fileActions';
import {createAction} from '@reduxjs/toolkit';
import {postData, getData} from '../../../utils/ajax';
import {schedulesSlice} from '../schedule/SchedulesSlice';
import {inspectionsSlice} from './InspectionsSlice';

const postInspectionRequest = createAction('postInspectionRequest');
const postInspectionSuccess = createAction('postInspectionSuccess');
const postInspectionFailure = createAction('postInspectionFailure');

const createInspectionRequest = createAction('createInspectionRequest');
const createInspectionSuccess = createAction('createInspectionSuccess');
const createInspectionFailure = createAction('createInspectionFailure');

export const inspectionSlice = createFetchableSlice({
    name: 'selectedInspection',
    fetchable: [
        {name: 'inspection', api: fetchInspection},
        {name: 'aspectElements', api: fetchInspectionAspectElements},
        {name: 'relatedWorkAssignments', api: fetchInspectionWorkAssignments},
        {name: 'relatedRequests', api: fetchInspectionRequests}
    ],
    extraInitialState: {
        isCreating: false,
        isPosting: false
    },
    reducers: {
        updateInspectionProperty: produce((draft, action) => {
            draft.data.inspection.data[action.payload.property] = action.payload.value;
        }),
        updateInspectionElementState: produce((draft, action) => {
            const {elementId, change} = action.payload;

            const inspection = draft.data.inspection.data;

            let elementState = inspection.statePerElement.find(s => s.element === elementId);
            if (elementState) {
                Object.assign(elementState, change);
            } else {
                inspection.statePerElement.push({
                    element: elementId,
                    state: change.state || 'MISSING',
                    stateDescription: change.stateDescription || null,
                    createRequest: change.createRequest || false,
                    requestDescription: change.requestDescription || '',
                    files: change.files || []
                });
            }
        }),
        postInspection: produce((draft, action) => {
            draft.selectedInspection.data.isPosting = !draft.selectedInspection.data.isPosting;
        })
    },
    extraReducers: {
        [postInspectionRequest]: (state, action) => ({...state, isPosting: true}),
        [postInspectionSuccess]: (state, action) => ({
            ...state,
            isPosting: false,
            error: null,
            data: {
                ...state.data,
                inspection: createDefaultInitialState(action.payload.inspection),
                relatedRequests: createDefaultInitialState(action.payload.relatedRequests)
            }
        }),
        [postInspectionFailure]: (state, action) => console.error(action.payload) || ({...state, isPosting: false, error: action.error}),
        [createInspectionRequest]: (state, action) => ({...state, isCreating: true, loaded: false}),
        [createInspectionSuccess]: (state, action) => ({
            ...state,
            isCreating: false,
            loaded: true,
            error: null,
            data: {
                ...state.data,
                inspection: createDefaultInitialState(action.payload),
                relatedRequests: createDefaultInitialState([]),
                relatedWorkAssignments: createDefaultInitialState([]),
            }
        }),
        [createInspectionFailure]: (state, action) => ({...state, isCreating: false, loaded: false, error: action.error})
    },
    actions: {
        uploadFiles: files => {
            return (dispatch, getState) => {
                const inspection = getState().selectedInspection.data.inspection.data;
                dispatch(uploadFiles(makeInspectionReference(inspection), files)).then(files => {
                    dispatch(updateInspectionProperty({property: 'files', value: [...inspection.files, ...files.map(f => f.id)]}))
                });
            }
        },
        uploadInspectionElementStateFiles: (elementId, files) => {
            return (dispatch, getState) => {
                const inspection = getState().selectedInspection.data.inspection.data;
                dispatch(uploadFiles(makeInspectionReference(inspection), files)).then(files => {
                    const existingState = inspection.statePerElement.find(st => st.element === elementId);
                    const existingFiles = existingState ? existingState.files : [];
                    dispatch(updateInspectionElementState({
                        elementId,
                        change: {
                            files: [...existingFiles, ...files.map(f => f.id)]
                        }
                    }));
                });
            }
        },
        shouldPostInspection: state => !state.selectedInspection.data.isPosting,
        postInspectionIfPossible: () => {
            return (dispatch, getState) => {
                if (shouldPostInspection(getState())) {
                    dispatch(postInspection()).then(() => {
                        alert(_tr('Your inspection has been saved.'));
                    })
                }
            };
        },
        postInspection: () => {
            return async (dispatch, getState) => {
                dispatch(postInspectionRequest());

                const state = getState();
                const inspection = state.selectedInspection.data.inspection.data;
                const inspectionPostData = {
                    aspect: inspection.aspect.id,
                    schedule: inspection.applyToSchedules.length ? inspection.applyToSchedules[0] : null,
                    state: inspection.state,
                    stateDescription: inspection.stateDescription,
                    statePerElement: inspection.statePerElement,
                    createRequest: inspection.createRequest,
                    requestDescription: inspection.requestDescription,
                    files: inspection.files
                };

                const inspectionId = inspection.id;
                try {
                    const createsRequests = inspectionPostData.createRequest ||
                        inspectionPostData.statePerElement.some(elementState => !!elementState.requestDescription);

                    const inspection = await postData(`${apiUrl}/inspections/${inspectionId}`, inspectionPostData);

                    let relatedRequests = state.selectedInspection.data.relatedRequests.data;
                    if (createsRequests) {
                        relatedRequests = await getData(`${apiUrl}/requests/by-inspection/${inspectionId}`);
                    }

                    dispatch(postInspectionSuccess({inspection, relatedRequests}));
                    dispatch(invalidate());
                    dispatch(inspectionsSlice.actions.invalidate());
                } catch (err) {
                    dispatch(postInspectionFailure(err));
                }
            }
        },
        shouldCreateInspection: state => !state.selectedInspection.isCreating,
        createInspectionIfNeeded: (aspectId, scheduleId) => {
            return (dispatch, getState) => {
                const state = getState();
                if (shouldCreateInspection(state)) {
                    dispatch(createInspection(aspectId, scheduleId));
                }
            }
        },
        createInspection: (aspectId, scheduleId) => {
            return async dispatch => {
                dispatch(createInspectionRequest());

                try {
                    const inspection = await getData(`${apiUrl}/inspections/new`, {
                        params: {
                            aspect: aspectId,
                            schedule: scheduleId
                        }
                    });

                    dispatch(createInspectionSuccess(inspection));

                    dispatch(invalidate());
                    dispatch(inspectionsSlice.actions.invalidate());
                    dispatch(schedulesSlice.actions.invalidate());
                } catch (err) {
                    console.error(err);
                    dispatch(createInspectionFailure(err));
                }
            }
        }
    }
});

export const makeInspectionReference = inspection =>
    new FileReference(EntityTypes.INSPECTION, inspection.id, `${apiUrl}/inspections/${inspection.id}/files`);

export const {updateInspectionProperty, updateInspectionElementState, uploadInspectionElementStateFiles, shouldPostInspection, postInspectionIfPossible, postInspection, createInspectionIfNeeded, shouldCreateInspection, createInspection, invalidate} = inspectionSlice.actions;

export default inspectionSlice.reducer;