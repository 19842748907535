import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {loadInspectionFromRoute, mapInspectionStateToProps} from './InspectionDetails';
import {View, ActivityIndicator, Text} from 'react-native';
import {BlockTitle} from '../../../components/BlockTitle';
import HeaderButton from '../../../components/HeaderButton';
import ElementsOverview from './ElementsOverview';

const InspectionLocationDetailsComponent = ({route, dispatch, navigation, inspection, aspectElements, loaded, didInvalidate}) => {
    const locationName = route.params.location;

    useEffect(() => {
        navigation.setOptions({
            headerRight: () => <HeaderButton title={_tr('OK')} onPress={() => navigation.goBack()} />
        });

        if (!inspection) {
            loadInspectionFromRoute(route, dispatch);
        }
    }, [route, didInvalidate]);

    let locationElements = null;
    if (loaded && aspectElements) {
        locationElements = aspectElements.filter(ae => ae.location === locationName);
    }

    return (
        <View>
            <BlockTitle withMargin>{`${_tr('Inspection')} - ${_tr('location')}`}: {locationName}</BlockTitle>
            {(!loaded || !aspectElements)
                ? <ActivityIndicator size='large' />
                : <ElementsOverview elements={locationElements} statePerElement={inspection.statePerElement} inspection={inspection}  />
            }
        </View>
    );
};

export default connect(mapInspectionStateToProps)(InspectionLocationDetailsComponent);
