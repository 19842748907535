import React from 'react';
import {ScrollView} from 'react-native';
import {useMediaQuery} from 'react-responsive';
import {TABLET_SCREEN_WIDTH_THRESHOLD} from '../constants';

export default ({children, ...props}) => {
    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });

    // Setting known height on a ScrollView makes onPress events on children less sensitive,
    // allowing users on mobile devices to scroll through a list without constantly triggering onPresses that should not be triggered.
    const height = isMobileDevice ? '500px' : 'unset';
    
    return (
        <ScrollView {...props} style={[{height}, props.style]}>
            {children}
        </ScrollView>
    );
};
