import React, {useEffect} from 'react';
import {View, ActivityIndicator, Text, StyleSheet} from 'react-native';
import {ListItem} from 'react-native-elements';
import {BlockTitle} from '../../components/BlockTitle';
import {ScrollView} from 'react-native-gesture-handler';
import {tenantDetailsSlice} from './tenantDetailsSlice';
import {connect} from 'react-redux';
import {formatCurrency} from "../../formatCurrency";
import FileListItem from "../../supplier-app/feature/file/FileListItem";

const TenantDetailsComponent = ({navigation, data, loaded, dispatch, didInvalidate}) => {
    useEffect(() => {
        dispatch(tenantDetailsSlice.actions.fetchIfNeeded());
    }, [didInvalidate]);

    if (!loaded) return <ActivityIndicator size='large' />;

    const rentalContract = (loaded && data.rentalContracts && data.rentalContracts.length)
        ? data.rentalContracts[0] // Jo says we can assume there is never more than one contract
        : null;
    if (!rentalContract) return <Text>{_tr('No rental contract details found')}</Text>;

    console.log({rentalContract});

    return (
        <ScrollView>
            <View>
                <BlockTitle withMargin>{_tr('Unit(s)')}</BlockTitle>
                {rentalContract.units.map((unit, i) => (
                    <ListItem key={unit.id} title={unit.adres.toString} rightTitle={unit.indexNr} topDivider={i === 0} />
                ))}

                <View>
                    <BlockTitle withMargin>{_tr('Rent')}</BlockTitle>
                    <ListItem title={_tr('Monthly amount')} rightTitle={`€ ${formatCurrency(rentalContract.gross)}`} topDivider/>
                    <ListItem title={_tr('Landlord account')} rightTitle={rentalContract.landlordAccountString} topDivider />
                    <ListItem title={_tr('Automatic debit collection')} rightTitle={rentalContract.automaticCollection ? _tr('Authorized') : _tr('No')} topDivider />
                </View>

                <View>
                    <BlockTitle withMargin>{_tr('Balance')}</BlockTitle>
                    {rentalContract.rentArrears >= 0 ? (
                        <ListItem title={_tr('Rent arrears')}
                                  rightTitle={`€ ${formatCurrency(rentalContract.rentArrears)}`}
                                  rightTitleStyle={rentalContract.rentArrears > 0 ? finStyle.finNegative : null}
                                  topDivider />
                    ) : (
                        <ListItem title={_tr('Rent advance')}
                                  rightTitle={`€ ${formatCurrency(-rentalContract.rentArrears)}`}
                                  rightTitleStyle={finStyle.finPositive}
                                  topDivider />

                    )}

                    <ListItem title={_tr('Deposit')} rightTitle={`€ ${formatCurrency(rentalContract.deposit)}`} topDivider />
                </View>



                <View>
                    <BlockTitle withMargin>{_tr('Contract')}</BlockTitle>
                    <ListItem title={_tr('Start date')} rightTitle={rentalContract.startDate} topDivider/>
                    <ListItem title={_tr('End date')} rightTitle={rentalContract.endDate} topDivider />
                    <ListItem title={_tr('Notice period')} rightTitle={rentalContract.noticePeriodInMonths + ' ' + _tr('month(s)')} topDivider />
                </View>

                {rentalContract.contractFile && (
                    <View>
                        <BlockTitle withMargin>{_tr('Contract file(s)')}</BlockTitle>
                        <FileListItem file={{
                            urlPrefix: '/file',
                            file: rentalContract.contractFile,
                            isFetching: false
                        }} modifiable={false} />
                    </View>
                )}
            </View>
        </ScrollView>
    );
};

const finStyle = StyleSheet.create({
    finPositive: {
        color: 'green',
        fontWeight: 'bold'
    },
    finNegative: {
        color: 'red',
        fontWeight: 'bold'
    }
});

const mapStateToProps = state => state.tenantDetails;

export const TenantDetails = connect(mapStateToProps)(TenantDetailsComponent);
