import React from "react";
import {View} from "react-native";
import FileList from '../file/FileList';
import {makeInspectionReference} from "./InspectionSlice";
import FileSelectButton from '../../../components/FileSelectButton';
import LocationsOverview from "./LocationsOverview";
import FormRow from "../../../components/FormRow";
import InspectionStateSelect from "../../../components/InspectionStateSelect";
import AspectTypeIcon from "../../../components/AspectTypeIcon";
import commonStyles from "../../../style/common";
import {ListItem} from "react-native-elements";
import TextArea from "../../../components/TextArea";

export default ({
    inspection,
    aspectElements,
    onChangeState,
    onChangeStateDescription,
    onChangeCreateRequest,
    onChangeRequestDescription,
    onUploadFiles,
    onRenameFile,
    onRemoveFile
}) => {
    const {aspect, state} = inspection;
    const {aspectType, complexName, complexAddress} = aspect;

    const handleFileChange = event => {
        const input = event.currentTarget;
        const files = input.files;
        onUploadFiles(files);
    };

    const createRequest = !!inspection.createRequest;
    const stateDescription = inspection.stateDescription || '';
    const requestDescription = inspection.requestDescription || '';

    return (
        <View>
            <FormRow
                title={<AspectTypeIcon aspectType={aspectType} state={state} />}
                rightTitle={aspectType.name}
                topDivider
            />
            {!!aspectType.description &&
                <FormRow rightTitle={aspectType.description} />}

            <FormRow title={_tr('Complex')} rightTitle={complexName} rightSubtitle={complexAddress.toString} />

            <FormRow title={`${_tr('State')}:`} rightTitle={
                <InspectionStateSelect value={inspection.state} onChange={e => onChangeState(e.currentTarget.value)} />
            } />

            <FormRow title={_tr('Images')} rightTitle={
                <FileSelectButton
                    title={_tr('Create / upload photo')}
                    onSelect={handleFileChange}
                    type='outline'
                    accept="image/*,video/*"
                    containerStyle={commonStyles.fullWidth} />
            } />

            {!!inspection.files &&
                <View>
                    <FileList reference={makeInspectionReference(inspection)} fileIds={inspection.files} onRename={onRenameFile} onRemove={onRemoveFile} modifiable />
                </View>
            }

            <FormRow title={`${_tr('State description')}:`} topDivider bottomDivider={false} />
            <TextArea
                placeholder={_tr('Enter your finding(s) here.')}
                value={stateDescription}
                onChangeText={onChangeStateDescription}
            />

            {(!!aspectElements && aspectElements.length > 0) &&
                <View>
                    <ListItem title={`${_tr('State per element')}:`} bottomDivider topDivider />
                    <LocationsOverview inspection={inspection} aspectElements={aspectElements} />
                </View>
            }

            <FormRow
                title={_tr('Make a report')}
                onPress={() => onChangeCreateRequest(!createRequest)}
                checkBox={{
                    checked: createRequest,
                    onPress: () => onChangeCreateRequest(!createRequest)
                }} />
            {!!inspection.createRequest &&
                <TextArea
                    style={commonStyles.supplierAppText}
                    placeholder={_tr('Describe the report')}
                    value={requestDescription}
                    onChangeText={onChangeRequestDescription} />}
        </View>
    );
};
