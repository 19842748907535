import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {ListItem} from 'react-native-elements';
import {connect} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {TABLET_SCREEN_WIDTH_THRESHOLD} from '../../constants';
import { logout } from '../../utils/Util';
import { setAppRoles, setAppType } from '../../login/LoginPageSlice';

const NavigationItem = props => (
    <ListItem containerStyle={styles.navigationItemContainer} titleStyle={styles.text} topDivider {...props} />
);

const switchToRequestApp = (dispatch) => {
    dispatch(setAppType("request-app"))
    dispatch(setAppRoles(["administrator"]))
}

const MainMenuPanel = ({dispatch, opened, appRoles}) => {
    if (!opened) return null;

    const isMobileDevice = useMediaQuery({
        maxDeviceWidth: TABLET_SCREEN_WIDTH_THRESHOLD
    });

    const panelStyle = [styles.panel, {width: isMobileDevice ? '100%' : 'max(10vw, 200px)'}];

    return (
        <View style={panelStyle}>
            <Text style={[styles.title, styles.text]}>{_tr('Navigation')}</Text>
            {appRoles.includes('administrator') &&
                <View style={styles.navigationItemsContainer}>
                    <NavigationItem title={_tr('Switch to request-app')} onPress={() => switchToRequestApp(dispatch)} />
                </View>
            }
            <View style={styles.navigationItemsContainer}>
                <NavigationItem title={_tr('Log out')} onPress={() => logout(dispatch)} />
            </View>
        </View>
    );
};

const mapStateToProps = state => ({
    ...state.mainMenuPanel,
    appRoles: state.loginPage.appRoles
})

export default connect(mapStateToProps)(MainMenuPanel);

const styles = StyleSheet.create({
    panel: {
        right: '0',
        height: '100vh',
        backgroundColor: '#393939'
    },
    title: {
        textAlign: 'center',
        backgroundColor: '#222426'
    },
    text: {
        fontSize: '17px',
        color: '#DDDDDD'
    },
    navigationItemsContainer: {
        marginTop: '30px'
    },
    navigationItemContainer: {
        backgroundColor: '#222426',
        padding: '10px',
        marginBottom: '5px'
    }
});
