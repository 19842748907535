import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {inspectionSlice, makeInspectionReference, updateInspectionProperty, createInspectionIfNeeded} from './InspectionSlice';
import {View, ActivityIndicator} from 'react-native';
import {BlockTitle} from '../../../components/BlockTitle';
import InspectionForm from './InspectionForm';
import RequestList from '../request/RequestList';
import WorkAssignmentList from '../workAssignment/WorkAssignmentList';
import {fetchFilesIfNeeded, renameFile} from '../file/fileActions';
import HeaderButton from '../../../components/HeaderButton';
import ScrollableView from '../../../components/ScrollableView';

export const loadInspectionFromRoute = (route, dispatch) => {
    const {inspection, aspectId, scheduleId} = route.params;

    if (!inspection) {
        dispatch(createInspectionIfNeeded(aspectId, scheduleId));
    } else {
        dispatch(inspectionSlice.actions.fetch(inspection)).then(result => {
            if (!result) return;

            const fetchedInspection = result.inspection.payload;

            dispatch(fetchFilesIfNeeded(makeInspectionReference(fetchedInspection), fetchedInspection.files));

            fetchedInspection.statePerElement && fetchedInspection.statePerElement.forEach(statePerElement => {
                dispatch(fetchFilesIfNeeded(makeInspectionReference(fetchedInspection), statePerElement.files));
            });
        });
    }
};

export const InspectionDetails = ({route, dispatch, navigation, inspection, aspectElements, relatedRequests, relatedWorkAssignments, loaded, didInvalidate}) => {
    useEffect(() => {
        navigation.setOptions({
            headerRight: () => <HeaderButton title={_tr('Save')} onPress={() => dispatch(inspectionSlice.actions.postInspectionIfPossible())} />
        });

        loadInspectionFromRoute(route, dispatch);
    }, [didInvalidate]);

    const handleOnChangeState = value => dispatch(updateInspectionProperty({property: 'state', value}));
    const handleOnChangeStateDescription = value => dispatch(updateInspectionProperty({property: 'stateDescription', value}));
    const handleOnChangeCreateRequest = value => dispatch(updateInspectionProperty({property: 'createRequest', value}));
    const handleOnChangeRequestDescription = value => dispatch(updateInspectionProperty({property: 'requestDescription', value}));
    const handleUploadFiles = files => dispatch(inspectionSlice.actions.uploadFiles(files));
    const handleRenameFile = (file, name) => dispatch(renameFile(makeInspectionReference(inspection), file, name));
    const handleRemoveFile = file => dispatch(updateInspectionProperty({property: 'files', value: inspection.files.filter(id => id !== file.id)}));

    return (
        <View>
            <BlockTitle withMargin>{_tr('Inspection')}</BlockTitle>
            {!loaded ?
                <ActivityIndicator size='large' />
                : <InspectionForm
                    inspection={inspection}
                    aspectElements={aspectElements}
                    relatedRequests={relatedRequests}
                    relatedWorkAssignments={relatedWorkAssignments}
                    onChangeState={handleOnChangeState}
                    onChangeStateDescription={handleOnChangeStateDescription}
                    onChangeCreateRequest={handleOnChangeCreateRequest}
                    onChangeRequestDescription={handleOnChangeRequestDescription}
                    onUploadFiles={handleUploadFiles}
                    onRenameFile={handleRenameFile}
                    onRemoveFile={handleRemoveFile}
                />}

            <View>
                <BlockTitle withMargin>{_tr('Requests')}</BlockTitle>
                <ScrollableView>
                    {(!loaded || !relatedRequests) ?
                        <ActivityIndicator size='large' />
                        : <RequestList requests={relatedRequests} />}
                </ScrollableView>
            </View>

            <View>
                <BlockTitle withMargin>{_tr('Work assignments')}</BlockTitle>
                <ScrollableView>
                    {(!loaded || !relatedWorkAssignments) ?
                        <ActivityIndicator size='large' />
                        : <WorkAssignmentList workAssignments={relatedWorkAssignments} />}
                </ScrollableView>

            </View>
        </View>
    )
};

export const mapInspectionStateToProps = state => {
    const {data, loaded, didInvalidate} = state.selectedInspection;

    return {
        inspection: data.inspection.data,
        aspectElements: data.aspectElements.data,
        relatedRequests: data.relatedRequests.data,
        relatedWorkAssignments: data.relatedWorkAssignments.data,
        loaded,
        didInvalidate
    };
};

export default connect(mapInspectionStateToProps)(InspectionDetails);
