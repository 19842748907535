const os = require('os');

const GETTEXT_VERSION = '0.19.8.1';
exports.GETTEXT_VERSION = GETTEXT_VERSION;
exports.LANGS = [ 'nl', 'en', 'de', 'fr' ];
exports.gettextCmd = function (cmd) {
	if (os.type() === 'Windows_NT') {
		return `.\\gettext-${GETTEXT_VERSION}\\bin\\${cmd}.exe`
	} else {
		// assume in $PATH
		return cmd;
	}
};
