import React from 'react';
import {StyleSheet, Text} from 'react-native';
import commonStyles from '../style/common';

export const BlockTitle = ({children, withMargin = false, ...props}) => (
    <Text style={[styles.blockTitle, (withMargin && commonStyles.smallMargin), props.style]}>{children}</Text>
);

const styles = StyleSheet.create({
    blockTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        lineHeight: 16,
        fontWeight: '400',
        fontSize: '18px',
        marginBottom: 16
    }
});