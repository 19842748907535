import React from 'react';
import {ScrollView, View, Text, StyleSheet, Image} from 'react-native';
import {performLogin} from '../Api';
import {Input, Button} from 'react-native-elements';
import {setUsername, setPassword} from './LoginFormSlice';
import {connect} from 'react-redux';
import commonStyles from '../style/common';

const LOGO_SIZE = 80;

const LoginForm = ({username, password, onLogin, dispatch}) => {
    const usernameRef = React.createRef();
    const passwordRef = React.createRef();

    const handleChangeUsername = val => dispatch(setUsername(val));
    const handleChangePassword = val => dispatch(setPassword(val));

    const handleLoginButton = () => {
        performLogin({username, password})
            .then(response => {
                if (response.status === 404) {
                    alert(_tr('Incorrect url'));
                } else if (response.request.responseURL.includes('error=true&reason=badCredentials')) {
                    alert(_tr('Incorrect username/password'));
                } else {
                    onLogin && onLogin();
                }
            })
            .catch(err => {
                console.error(err);
                alert(_tr('Something went wrong while logging in. Please try again.'));
            });
    }

    return (
        <ScrollView>
            <View style={styles.header}>
                <Image style={styles.logo} source={require('../img/bimkeeper-logo-beeldmerk.svg')} accessibilityLabel={_tr('BIMkeeper logo')} />
                <View style={commonStyles.alignCenter}>
                    <Text style={styles.title}>{_tr('BIMkeeper')}</Text>
                    <Text style={styles.subtitle}>{window.location.hostname}</Text>
                </View>
            </View>
            <View style={commonStyles.alignCenter}>
                <View style={styles.form}>
                    <View style={styles.formItem}>
                        <Text>{_tr('Username')}</Text>
                        <Input
                            placeholder={_tr('username')}
                            ref={usernameRef}
                            value={username}
                            onChangeText={handleChangeUsername}
                            returnKeyType='next'
                            onSubmitEditing={() => passwordRef.current && passwordRef.current.focus()} />
                    </View>
                    <View style={styles.formItem}>
                        <Text>{_tr('Password')}</Text>
                        <Input
                            placeholder={_tr('password')}
                            secureTextEntry
                            ref={passwordRef}
                            value={password}
                            onChangeText={handleChangePassword}
                            returnKeyType='go'
                            onSubmitEditing={handleLoginButton} />
                    </View>

                    <View style={styles.formItem}>
                        <Button title={_tr('Login')} type='clear' onPress={handleLoginButton} style={styles.loginButton} />
                    </View>
                </View>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    header: {
        marginVertical: 20,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    logo: {
        width: `${LOGO_SIZE}px`,
        height: `${LOGO_SIZE}px`
    },
    title: {
        fontSize: 34
    },
    subtitle: {
        fontSize: 16
    },
    form: {
        marginVertical: 30,
        width: '100%',
        maxWidth: '480px',
        alignItems: 'center'
    },
    formItem: {
        width: '100%',
        paddingHorizontal: 10
    },
    inputContainer: {
        width: '100%',
        paddingHorizontal: 0
    },
    loginButton: {
        marginVertical: 20
    }
});

const mapStateToProps = state => state.loginForm;

export default connect(mapStateToProps)(LoginForm);
