import {DefaultTheme} from '@react-navigation/native';

export const headerBackgroundColor = '#2196F3';

export const defaultTheme = {
    ...DefaultTheme,
    dark: false,
    colors: {
        ...DefaultTheme.colors,
        background: 'white'
    }
};

export const defaultHeaderStyles = {
    headerStyle: {
        backgroundColor: headerBackgroundColor
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
        fontSize: 20,
        fontWeight: '500'
    }
};

export const defaultOptions = () => ({
    title: _tr('BIMkeeper app'),
    headerTitle: _tr('BIMkeeper'),
    ...defaultHeaderStyles
});

export const blockHeaderStyles = {
    darkWithPadding: {
        backgroundColor: '#333333',
        color: 'white',
        paddingTop:'10px',
        paddingLeft:'10px',
        paddingRight:'10px',
        paddingBottom:'16px',
		margin: 0,
        fontWeight: '500'
    }
}