export class FileReference {
	entityType;
	entityId;
	url;

	constructor(entityType, entityId, url) {
		this.entityType = entityType;
		this.entityId = entityId;
		this.url = url;
	}

	get key() {
		return `${this.entityType}:${this.entityId}@${this.url}`;
	}

	static fromKey(key) {
		const keySplit = key.split('@');
		const entityTypeAndId = keySplit[0].split[':'];
		const url = keySplit[1];
		const entityType = entityTypeAndId[0];
		const entityId = parseInt(entityTypeAndId[1], 10);
		return new FileReference(entityType, entityId, url);
	}
}

export const EntityTypes = {
	INSPECTION: 'inspection',
	//WORKASSIGNMENT
};

window.FileReference = FileReference;