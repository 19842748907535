import {StyleSheet} from "react-native";

export default StyleSheet.create({
    smallMargin: {
        margin: 10
    },
    fullSize: {
        width: '100%',
        height: '100%'
    },
    white: {
        color: 'white'
    },
    whiteBg: {
        backgroundColor: 'white'
    },
    bold: {
        fontWeight: 'bold'
    },
    alignCenter: {
        alignItems: 'center'
    },
    noPadding: {
        padding: 0
    },
    fullWidth: {
        width: '100%'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    supplierAppText: {
        fontSize: '17px',
        fontWeight: '400',
        color: 'black'
    },
    heavyText: {
        fontSize: '17px',
        fontWeight: '500',
        color: 'black'
    },
    subText: {
        fontSize: '15px',
        color: 'grey'
    }
});