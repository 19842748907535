import React from "react";
import {View} from "react-native";
import {BlockTitle} from "../../../components/BlockTitle";
import FileSelectButton from "../../../components/FileSelectButton";
import FileList from "../file/FileList";
import {makeInspectionReference} from "./InspectionSlice";
import FormRow from "../../../components/FormRow";
import InspectionStateSelect from "../../../components/InspectionStateSelect";
import commonStyles from "../../../style/common";
import TextArea from "../../../components/TextArea";

export default ({inspection, aspectElement, elementState, onChange, onFileChange, onRenameFile, onRemoveFile}) => {
    const stateValue = elementState ? elementState.state : 'MISSING';
    const stateDescription = elementState ? elementState.stateDescription || '' : '';
    const createRequest = elementState ? elementState.createRequest : false;
    const requestDescription = elementState ? elementState.requestDescription || '' : '';
    const stateFiles = elementState ? elementState.files : [];

    const handleChangeState = e => {
        onChange(aspectElement.id, {
            state: e.currentTarget.value || 'MISSING'
        });
    };
    const handleChangeStateDescription = description => {
        onChange(aspectElement.id, {
            stateDescription: description || ''
        });
    };

    const handleChangeStateMakeRequest = value => {
        onChange(aspectElement.id, {
            createRequest: !!value
        });
    };

    const handleChangeStateRequestDescription = value => {
        onChange(aspectElement.id, {
            requestDescription: value || ''
        });
    };

    const handleChangeFiles = e => {
        onFileChange(e, aspectElement.id);
    };

    const handleRenameFile = (file, name) => {
        onRenameFile(aspectElement.id, file, name);
    };

    const handleRemoveFile = file => {
        onRemoveFile(file);
    };

    return (
        <View>
            <BlockTitle withMargin>{_tr('Element information')}</BlockTitle>
            <View>
                <FormRow title={_tr('Code')} rightTitle={
                    aspectElement.elementCode
                        ? (`${aspectElement.elementCode.fullCode} ${aspectElement.elementCode.name}`)
                        : null
                } />
                <FormRow title={_tr('Label')} rightTitle={aspectElement.label} />
                <FormRow title={_tr('Location')} rightTitle={aspectElement.location} />
            </View>

            <BlockTitle withMargin>{_tr('Element findings (to be filled in)')}</BlockTitle>
            <View>
                <FormRow title={_tr('State')} rightTitle={
                    <InspectionStateSelect value={stateValue} onChange={handleChangeState} />
                } />

                <FormRow title={_tr('Findings')} bottomDivider={false} />
                <TextArea
                    placeholder={_tr('Enter your finding(s) here.')}
                    value={stateDescription}
                    onChangeText={handleChangeStateDescription} />

                <FormRow contentContainerStyle={commonStyles.fullWidth} topDivider title={
                    <View style={commonStyles.fullWidth}>
                        <FileSelectButton
                            title={_tr('Create / upload photo')}
                            value={stateFiles}
                            onSelect={handleChangeFiles}
                            accept='image/*,video/*'
                            type='outline'
                            containerStyle={commonStyles.fullWidth} />
                        {stateFiles.length > 0 &&
                            <FileList reference={makeInspectionReference(inspection)} fileIds={stateFiles} onRename={handleRenameFile} onRemove={handleRemoveFile} modifiable />
                        }
                    </View>
                } />

                <FormRow
                    title={_tr('Make a report')}
                    onPress={() => handleChangeStateMakeRequest(!createRequest)}
                    checkBox={{
                        checked: createRequest,
                        onPress: () => handleChangeStateMakeRequest(!createRequest)
                    }} />
                {createRequest &&
                    <TextArea
                        placeholder={_tr('Describe the report')}
                        value={requestDescription}
                        onChangeText={handleChangeStateRequestDescription} />
                }
            </View>
        </View>
    );
};
